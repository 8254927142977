import React from 'react';

function UserDashbord() {
  return (
    <div className='bg-red-300'>
      <h1>UserDashbord page</h1>
     
    </div>
  );
}

export default UserDashbord;
