import React, { useState } from 'react';
import '../../../App.css'; // Import the external CSS file
import axiosInstance from '../../../api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

const UploadImages = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const maxSize = 2 * 1024 * 1024; // 2MB
  const maxImages = 5;
  const navigate = useNavigate();

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
  
    // Filter out files that exceed the size limit
    const filteredFiles = files.filter((file) => file.size <= maxSize);
  
    // Check if adding these files exceeds the maximum image limit
    if (selectedFiles.length + filteredFiles.length > maxImages) {
      alert('You can upload a maximum of 5 images.');
      return;
    }
  
    // Update the selectedFiles state with valid files
    setSelectedFiles((prevSelectedFiles) => [...prevSelectedFiles, ...filteredFiles]);
  
    // Log the updated state for debugging
    console.log("Updated selectedFiles:", selectedFiles);
  };
  
  

  const handleRemoveImage = (index) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files);

    // Filter out files that exceed the size limit
    const filteredFiles = files.filter((file) => file.size <= maxSize);

    // Check if adding these files exceeds the maximum image limit
    if (selectedFiles.length + filteredFiles.length > maxImages) {
      alert('You can upload a maximum of 5 images.');
      return;
    }

    // Update the selectedFiles state with valid files
    setSelectedFiles([...selectedFiles, ...filteredFiles]);
  };
  
  const appendFilesToFormData = () => {
    return new Promise((resolve) => {
      // Create FormData object
      const formData = new FormData();
  
      // Append each selected file to FormData
      selectedFiles.forEach((file, index) => {
        formData.append(`images[]`, file);
      });
  
      resolve(formData);
    });
  };
  
  const handleUpload = async () => {
    try {
      const formData = await appendFilesToFormData();
    
      // Check if formData is not empty
      if (formData) {
        const response = await axiosInstance.post('/uploadUserImages', formData);
    
        if (response.status === 200) {
          toast.success(response.data.message);
    
          // Wait for 1 second before navigating
          await new Promise(resolve => setTimeout(resolve, 3000));
          navigate(-1);
        } else if (response.status === 422) {
          toast.error(response.data.message);
        }
      } else {
        // Display an error toast if formData is empty
        toast.error('No files selected for upload');
      }
    } catch (error) {
      console.error('Error:', error);
    
      // Display an error toast if there is an error during the upload
      toast.error(error.message);
    }
    
  };
  
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
         <ToastContainer />
 <div
      className="upload__box"
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      
    >
      <div className="upload__header">
        <h2 className="custom-heading">Please Upload Profile Image</h2>
      </div>

      <div className="Neon Neon-theme-dragdropbox">
        <label
          className="upload__btn"
          style={{
            zIndex: 999,
            opacity: 0,
            width: '320px',
            height: '200px',
            position: 'absolute',
            right: '0',
            left: '0',
            marginRight: 'auto',
            marginLeft: 'auto',
          }}
        >
          <p>Choose Images</p>
          <input
            type="file"
            multiple
            data-max_length="20"
            className="upload__inputfile"
            onChange={handleFileChange}
          />
        </label>
        <div className="upload__img-wrap">
          {selectedFiles.map((file, index) => (
            <div key={index} className="upload__img-box">
              <img
                src={URL.createObjectURL(file)}
                alt={`Image ${index + 1}`}
              />
              <div
                className="upload__img-close"
                onClick={() => handleRemoveImage(index)}
              >
                &#x2716;
              </div>
            </div>
          ))}
        </div>
        <div className="Neon-input-dragDrop">
          <div className="Neon-input-inner">
            <div className="Neon-input-icon">
              <i className="fa fa-file-image-o"></i>
            </div>
            <div className="Neon-input-text">
              <h3>Drag&Drop files here</h3>
              <span style={{ display: 'inline-block', margin: '15px 0' }}>
                or
              </span>
            </div>
            <a className="Neon-input-choose-btn blue">Browse Files</a>
          </div>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <button className="upload__btn" onClick={handleUpload}>
        Upload Images
      </button>
    </div>
    </div>
    </div>
   
  );
};

export default UploadImages;
