import img1 from "../../../assests/home/newstmembers/1.jpeg";
import img2 from "../../../assests/home/newstmembers/2.jpg";
import img3 from "../../../assests/home/newstmembers/3.jpeg";
import img4 from "../../../assests/home/newstmembers/4.jpg";
import img5 from "../../../assests/home/newstmembers/5.jpg";
import img6 from "../../../assests/home/newstmembers/6.jpg";
import img7 from "../../../assests/home/newstmembers/7.jpg";
import img8 from "../../../assests/home/dummymembers/g1.jpg";
import img9 from "../../../assests/home/dummymembers/g2.jpg";
import img10 from "../../../assests/home/dummymembers/g3.jpg";
import img11 from "../../../assests/home/dummymembers/p1.jpg";
import img12 from "../../../assests/home/dummymembers/p2.jpg";
import img13 from "../../../assests/home/dummymembers/p3.jpg";
import img14 from "../../../assests/home/dummymembers/p4.jpg";
import img15 from "../../../assests/home/dummymembers/p5.jpg";

const Data = [
  { id: 1, name: 'Image 1', url: img1, username: 'Prashanthi', Age: '28', where: 'Jaffna' , Aducation: 'Diploma' , Language: 'Tamil' , Gender: 'Female'},
  { id: 2, name: 'Image 2', url: img2, username: 'Priya', Age: '24 Years', where: 'India' , Aducation: 'Higher Diploma' , Language: 'Tamil' , Gender: 'Female'},
  { id: 3, name: 'Image 3', url: img3, username: 'Anjali', Age: '25 Years', where: 'Jaffna' , Aducation: 'Bachelor' , Language: 'Tamil' , Gender: 'Female'},
  { id: 4, name: 'Image 4', url: img4, username: 'Kaveri', Age: '28 Years', where: 'Jaffna' , Aducation: 'Higher Diploma' , Language: 'Tamil' , Gender: 'Female'},
  { id: 5, name: 'Image 5', url: img5, username: 'Janani', Age: '27 Years', where: 'Canada' , Aducation: 'Bachelor' , Language: 'Tamil' , Gender: 'Female'},
  { id: 6, name: 'Image 6', url: img6, username: 'Saravanan', Age: '29 Years', where: 'Jaffna' , Aducation: 'Diploma' , Language: 'Tamil' , Gender: 'male'},
  { id: 7, name: 'Image 7', url: img7, username: 'Michael', Age: '31 Years', where: 'UK' , Aducation: 'Bachelor' , Language: 'Tamil' , Gender: 'male'},
  { id: 8, name: 'Image 8', url: img8, username: 'Divya', Age: '28 Years', where: 'Jaffna' , Aducation: 'Diploma' , Language: 'Tamil' , Gender: 'Female'},
  { id: 9, name: 'Image 9', url: img9, username: 'Anjali ', Age: '27 Years', where: 'Jaffna' , Aducation: 'Higher Diploma' , Language: 'Tamil' , Gender: 'Female'},
  { id: 10, name: 'Image 10', url: img10, username: 'Mathani ', Age: '31 Years', where: 'Jaffna' , Aducation: 'Higher Diploma' , Language: 'Tamil' , Gender: 'Female'},
  { id: 11, name: 'Image 11', url: img11, username: 'Prashan ', Age: '34 Years', where: 'Jaffna' , Aducation: 'Bachelor' , Language: 'Tamil' , Gender: 'male'},
  { id: 12, name: 'Image 12', url: img12, username: 'Balakumar ', Age: '32 Years', where: 'Jaffna' , Aducation: 'Bachelor' , Language: 'Tamil' , Gender: 'male'},
  { id: 13, name: 'Image 13', url: img13, username: 'Krishanth ', Age: '33 Years', where: 'Jaffna' , Aducation: 'Bachelor' , Language: 'Tamil' , Gender: 'male'},
  { id: 14, name: 'Image 14', url: img14, username: 'Suthan ', Age: '30 Years', where: 'Jaffna' , Aducation: 'Higher Diploma' , Language: 'Tamil' , Gender: 'male'},
  { id: 14, name: 'Image 15', url: img15, username: 'Yogesh ', Age: '35 Years', where: 'Jaffna' , Aducation: 'Higher Diploma' , Language: 'Tamil' , Gender: 'male'},

];




export default Data;