import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { useState } from 'react';
import RUserInfo from './RUserInfo';
import TermsCon from './TermsCon';
import Subscription from './Subscription';
import PaymentDetails from './paymentDetails/PaymentDetails';
import axiosInstance from '../../../api';
import ConfirmationDialog from './userProfilePicUploader'; // Import the ConfirmationDialog component
import axios from 'axios';
import { useEffect } from 'react';
import SurveyForm from './surveyform/SurveyForm';
import './RegProccess.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Margin } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function RegProcesswrap() {
  const [openDialog, setOpenDialog] = useState(false);
  const [step, setStep] = useState(1);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [open, setOpen] = React.useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paymentProcess = queryParams.get('paymentProcess');
  const packageProcess = queryParams.get('packageProcess');
  const [duration, setDuration] = useState('threemonths');
  const [loading, setLoading] = useState(false);
  const [loadingskipbtn, setLoadingskipbtn] = useState(false);
  const [loadingskipbtnpressed, setLoadingskipbtnpressed] = useState(false);

  useEffect(() => {
    // Check if paymentProcess is true and set the step to 5
    if (paymentProcess === 'true') {
      setLoading(true);
      axiosInstance
        .get('/repaymenttogetdata')
        .then((response) => {
          console.log(response.data.activeUserWithImagesAndDetails.paymentData);
  
          const userDetails = response.data.activeUserWithImagesAndDetails.details;
          const subscriptionDetails = {
            plan: response.data.activeUserWithImagesAndDetails.paymentData.plan,
            duration: response.data.activeUserWithImagesAndDetails.paymentData.duration,
            promoCode: response.data.activeUserWithImagesAndDetails.paymentData.promoCode,
            promoCodeDiscount: response.data.activeUserWithImagesAndDetails.paymentData.promoCodeDiscount,
            subtotal: response.data.activeUserWithImagesAndDetails.paymentData.subtotal,
            Packagetotal: response.data.activeUserWithImagesAndDetails.paymentData.Packagetotal,
            cardNumber: response.data.activeUserWithImagesAndDetails.paymentData.cardNumber,
            expiryDate: response.data.activeUserWithImagesAndDetails.paymentData.expiryDate,
            cvv: response.data.activeUserWithImagesAndDetails.paymentData.cvv,
            payment: response.data.activeUserWithImagesAndDetails.paymentData.payment
          };
  
          // Update the user details and subscription details in the state
          setValues({
            ...userDetails,
            termsagree: userDetails.termsagree === 1, // Convert termsagree to boolean
            selectedPlans: userDetails.selectedPlans || '',
            selectedSurvey: userDetails.selectedSurvey || '',
            subscriptionDetails: [subscriptionDetails],
          });
  
          setDuration(response.data.activeUserWithImagesAndDetails.paymentData.duration);
          setStep(5);
        })
        .catch((error) => {
          console.log(error);
        }).finally(() => {
          setLoading(false); 
        });
        
    }
    else if (packageProcess === 'true') {
      setLoading(true);
      axiosInstance
        .get('/repaymenttogetdata')
        .then((response) => {
          console.log(response.data.activeUserWithImagesAndDetails.paymentData);
  
          const userDetails = response.data.activeUserWithImagesAndDetails.details;
          const subscriptionDetails = {
            plan: response.data.activeUserWithImagesAndDetails.paymentData.plan,
            duration: response.data.activeUserWithImagesAndDetails.paymentData.duration,
            promoCode: response.data.activeUserWithImagesAndDetails.paymentData.promoCode,
            promoCodeDiscount: response.data.activeUserWithImagesAndDetails.paymentData.promoCodeDiscount,
            subtotal: response.data.activeUserWithImagesAndDetails.paymentData.subtotal,
            Packagetotal: response.data.activeUserWithImagesAndDetails.paymentData.Packagetotal,
            cardNumber: response.data.activeUserWithImagesAndDetails.paymentData.cardNumber,
            expiryDate: response.data.activeUserWithImagesAndDetails.paymentData.expiryDate,
            cvv: response.data.activeUserWithImagesAndDetails.paymentData.cvv,
            payment: response.data.activeUserWithImagesAndDetails.paymentData.payment
          };
  
          // Update the user details and subscription details in the state
          setValues({
            ...userDetails,
            termsagree: userDetails.termsagree === 1, // Convert termsagree to boolean
            selectedPlans: userDetails.selectedPlans || '',
            selectedSurvey: userDetails.selectedSurvey || '',
            subscriptionDetails: [subscriptionDetails],
          });
  
          setDuration(response.data.activeUserWithImagesAndDetails.paymentData.duration);
          setStep(4);
        })
        .catch((error) => {
          console.log(error);
        }).finally(() => {
          setLoading(false); 
        });
      

    }
  }, [paymentProcess]);
  
  
  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };
  const [values, setValues] = useState({
    // Initialize with default values or an empty object if needed
    livingCountry: '',
    religion: '',
    cast: '',
    denomination: '',
    bornCountry: '',
    connectingJaffna: '',
    gender: '',
    marriedStatus: '',
    spokenLanguage: '',
    province: '',
    cityTown: '',
    pno: '',
    age: '',
    education: '',
    workDetails: '',
    heightInFt: '',
    weight: '',
    foodReference:'',
    termsagree: false,
    selectedPlans: '',
    selectedSurvey: '',
    subscriptionDetails:[]
  });

  const handleChange = (fieldName, fieldValue) => {
    setValues({
      ...values,
      [fieldName]: fieldValue,
    });
  };

  const [termsagree, settermsagree] = useState(false);

  const handletermsagreeChange = () => {
    const newtermsagreeValue = !termsagree;

    settermsagree(newtermsagreeValue);
    setValues({
      ...values, // Spread the existing values
      termsagree: !values.termsagree, // Toggle the 'termsagree' field
    });
  };
  const handleSelectedPlans = (selectedPlans) => {
    // Update the values state with the selected plans array
    setValues({
      ...values,
      selectedPlans, // Assuming you want to store the selected plans in values.selectedPlans
    });
  };
  
  const handleSelectedSurvey = (selectedSurvey) => {
    // Update the values state with the selected survey
    setValues({
      ...values,
      selectedSurvey: selectedSurvey,
    });
  };
  
  const updateSubscriptionDetails = (subscriptionDetails) => {
    setValues({
      ...values,
      subscriptionDetails,
    });
    console.log(values);
  };
  
  const updateSubscriptionDetailsbool = (booldata) => {
    console.log(booldata);
    setLoadingskipbtn(booldata);
  };

  useEffect(() => {
    console.log(values);
  }, [values, loadingskipbtn]);

  const nextStep = () => {
  console.log(values);
    //console.log(values);
    if (step === 2 && termsagree) {
      // Only proceed to the next step (3) if termsagree is true in step 2
      setStep(3);
    }else if(step === 4 && values.selectedPlans == ''){
      setStep(4);
      toast.warning("Please Select Your Subscription Plan");
    }else if(step === 5 && values.subscriptionDetails.payment == false){
      setStep(5);
      toast.warning("Please settle your payment");
    }else if (step === 1 && values.gender === '') {
      toast.warning("Please Select Your Gender");
    }else if (step === 1 && values.pno === '') {
      toast.warning("Please Select Your Phone No");
    }else if (step === 1 && values.pno.trim().length !== 10) {
      toast.warning("Please enter a valid 10-digit Phone Number");
    }else {
      setStep(step + 1);
    }
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const handleSubmit = () => {
    console.log(values.subscriptionDetails);

    if(values.subscriptionDetails.length == 0){
      toast.warning("Please settle your payment");
      return;
    }

   // Check if subscriptionDetails is an array and has at least one item
    if (values.subscriptionDetails.payment == false) {
      toast.warning("Please settle your payment");
    }else{
    
    axiosInstance
      .post('/insertUserDatial', values)
      .then((response) => {
        setOpenDialog(true); 
        toast.success("Welcome to Jaffna Marriage");
        setTimeout(() => {
          console.log(values);
          window.location.href = '/browse';
        }, 2000);
      })
      .catch((error) => {

      });
     }
  };

  const handleSubmitSkip = () => {
    console.log(values.subscriptionDetails);
    setLoadingskipbtnpressed(true);
    axiosInstance
      .post('/insertUserDatial', values)
      .then((response) => {
        setOpenDialog(true); 
        toast.success("Welcome to Jaffna Marriage");
        setTimeout(() => {
          console.log(values);
          setLoadingskipbtnpressed(false);
          window.location.href = '/browse';
        }, 2000);
      })
      .catch((error) => {
        setOpenDialog(true); 
        toast.success(error);
        setLoadingskipbtnpressed(false);
      });
     
  };

  const handleClose = () => {
    setOpen(false);
  };
  const getStepStatus = (currentStep) => {
    if (currentStep < step) {
      return 'completed';
    } else if (currentStep === step) {
      return 'active';
    } else {
      return 'inactive';
    }
  };

  const handleCancel = () => {
    // Add your logic here to determine whether the dialog should be closed or not
    const shouldCloseDialog = true; // Set this to true or false based on your condition

    if (shouldCloseDialog) {
      setOpenDialog(false); // Close the dialog if shouldCloseDialog is true
    }
  };

  return (
    <div>
    {loading ? ( // Show loader while loading is true
      <div class="spinner">
      <div class="react1"></div>
      <div class="react2"></div>
      <div class="react3"></div>
      <div class="react4"></div>
      <div class="react5"></div>
  </div>
    ) : (
      <div className="flex justify-center temsCont items-center min-h-screen bg-[conic-gradient(at_top,_var(--tw-gradient-stops))] from-amber-900 to-yellow-300">
      {/* register form stepper window */}
      <ToastContainer />
      <div className="w-11/12 md:w-861 h-11/12 md:h-651 max-w-screen-lg mt-5 bg-white rounded-lg shadow-xl">
        <div  className="p-6">
          <div className="flex items-center justify-center">
            <div className="flex items-center">
              <div
                className={`rounded-full h-5 w-5 sm:w-6 md:w-7 lg:w-8 sm:h-6 md:h-7 lg:h-8 flex items-center justify-center text-white ${getStepStatus(1) === 'completed' ? 'bg-orange-500' : 'bg-gray-500'
                  }`}
              >
                {getStepStatus(1) === 'completed' ? (
                  <span className="text-white">✓</span>
                ) : (
                  <span className="text-white">{1}</span>
                )}
              </div>
              <div
                className={`h-1 w-10 sm:w-16 md:w-24 lg:w-32 ${getStepStatus(1) === 'completed' ? 'bg-orange-500' : 'bg-gray-500'
                  }`}
              ></div>
            </div>
            <div className="flex items-center">
              <div
                className={`rounded-full h-5 w-5 sm:w-6 md:w-7 lg:w-8 sm:h-6 md:h-7 lg:h-8 flex items-center justify-center text-white ${getStepStatus(2) === 'completed' ? 'bg-orange-500' : 'bg-gray-500'
                  }`}
              >
                {getStepStatus(2) === 'completed' ? (
                  <span className="text-white">✓</span>
                ) : (
                  <span className="text-white">{2}</span>
                )}
              </div>
              <div
                className={`h-1 w-10 sm:w-16 md:w-24 lg:w-32 ${getStepStatus(2) === 'completed' ? 'bg-orange-500' : 'bg-gray-500'
                  }`}
              ></div>
            </div>
            <div className="flex items-center">
              <div
                className={`rounded-full h-5 w-5 sm:w-6 md:w-7 lg:w-8 sm:h-6 md:h-7 lg:h-8 flex items-center justify-center text-white ${getStepStatus(3) === 'completed' ? 'bg-orange-500' : 'bg-gray-500'
                  }`}
              >
                {getStepStatus(3) === 'completed' ? (
                  <span className="text-white">✓</span>
                ) : (
                  <span className="text-white">{3}</span>
                )}
              </div>
              <div
                className={`h-1 w-10 sm:w-16 md:w-24 lg:w-32 ${getStepStatus(3) === 'completed' ? 'bg-orange-500' : 'bg-gray-500'
                  }`}
              ></div>
            </div>
            <div className="flex items-center">
              <div
                className={`rounded-full h-5 w-5 sm:w-6 md:w-7 lg:w-8 sm:h-6 md:h-7 lg:h-8 flex items-center justify-center text-white ${getStepStatus(4) === 'completed' ? 'bg-orange-500' : 'bg-gray-500'
                  }`}
              >
                {getStepStatus(4) === 'completed' ? (
                  <span className="text-white">✓</span>
                ) : (
                  <span className="text-white">{4}</span>
                )}
              </div>
              <div
                className={`h-1 w-10 sm:w-16 md:w-24 lg:w-32 ${getStepStatus(4) === 'completed' ? 'bg-orange-500' : 'bg-gray-500'
                  }`}
              ></div>
            </div>
            <div className="flex items-center">
              <div
                className={`rounded-full h-5 w-5 sm:w-6 md:w-7 lg:w-8 sm:h-6 md:h-7 lg:h-8 flex items-center justify-center text-white ${getStepStatus(5) === 'completed' ? 'bg-orange-500' : 'bg-gray-500'
                  }`}
              >
                {getStepStatus(5) === 'completed' ? (
                  <span className="text-white">✓</span>
                ) : (
                  <span className="text-white">{5}</span>
                )}
              </div>
            </div>
            
          </div>
        </div>

        <div className="p-6" >
          {step === 1 && (
            <RUserInfo nextStep={nextStep} handleChange={handleChange} values={values} />
          )}
          {step === 2 && (
            <TermsCon
              nextStep={nextStep}
              prevStep={prevStep}
              handletermsagreeChange={handletermsagreeChange}
              values={values}
              termsagree={termsagree}
            />
          )}
          {step === 3 && (
                    <div className='subscriptionComponent'>
                    <SurveyForm handleSelectedSurvey={handleSelectedSurvey} values={values} />
              </div>
                  )}
          {step === 4 && (
            <div className='subscriptionComponent'>
                 <Subscription prevStep={prevStep} handleSelectedPlans={handleSelectedPlans} values={values} />
            </div>
         )}
         {step === 5 && (
          <div className='subscriptionComponent'>
          <PaymentDetails duration={duration} values={values} updateSubscriptionDetails={updateSubscriptionDetails} updateSubscriptionDetailsbool ={updateSubscriptionDetailsbool } />
     </div>
         )}
        </div>
        {/* <ConfirmationDialog open={openDialog} onClose={handleCancel} /> */}


        
        <div className="flex justify-between p-6">
          {step !== 1 && (
            <button className="bg-[conic-gradient(at_top_right,_var(--tw-gradient-stops))] from-gray-200 via-gray-400 to-gray-600 text-white font-bold py-2 px-4 rounded" onClick={prevStep}>
              Previous
            </button>
          )}
          {step !== 5 ? (
            <button className="bg-gradient-to-r from-orange-600 to-orange-500 text-white font-bold py-2 px-4 rounded" onClick={nextStep} disabled={(step === 2 && !termsagree) }>
              Next
            </button>
          ) : (
            <div>
            {/* <button className="bg-gradient-to-r from-orange-600 to-orange-500 font-bold text-white py-2 px-4 rounded"onClick={handleSubmit}>
              Submit
            </button> */}
        {
  loadingskipbtn ? (
    
      loadingskipbtnpressed ? (
        <button
          className="bg-gradient-to-r from-orange-600 to-orange-500 font-bold text-white py-2 px-4 rounded"
          disabled={loadingskipbtn}
          onClick={handleSubmitSkip}
        >
          Skipping...
        </button>
      ) : (
        <button
          className="bg-gradient-to-r from-orange-600 to-orange-500 font-bold text-white py-2 px-4 rounded"
          onClick={() => {
            setLoadingskipbtn(true); // Set to true when the button is clicked
            handleSubmitSkip(); // Initiate the skipping process
          }}
        >
          Skip
        </button>
      )
    
  ) : null
}

          </div>
          )}
          

        </div>

        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>{"Use Google's location service?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Let Google help apps determine location. This means sending anonymous
              location data to Google, even when no apps are running.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Distermsagree</Button>
            <Button onClick={handleClose}>termsagree</Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
    )}
  </div>
  
  );
}

export default RegProcesswrap;
