// ########################## Gihan 06.11.23 ###################################

import img1 from "../../../../assests/home/sucessStories/image1.jpg";
import img2 from "../../../../assests/home/sucessStories/image2.jpg";
import img3 from "../../../../assests/home/sucessStories/image3.jpg";
import img4 from "../../../../assests/home/sucessStories/image4.jpg";
import img5 from "../../../../assests/home/sucessStories/image5.jpg";



const imageData=[
    { id: 1, name: 'Image 1', url: img1 ,title:"Praveen Kumar & Jayachitra",des:"JaffnaMarriage.com இல் எங்கள் பயணம் ஒரு எளிய 'hello' உடன் தொடங்கியது. முடிவில்லாத உரையாடல்களுக்கும், சிரிப்புக்கும்,  காதலுக்கும் வழிவகுத்தது. ஒருவரையொருவர் கண்டுபிடிக்க உதவிய இந்த தளத்திற்கு நாங்கள் நன்றியுள்ளவர்களாக இருக்கிறோம்."},
    { id: 2, name: 'Image 2', url: img2 ,title:"Prabhu and Nalini" ,des:"Really pleased at how respectful the members were on this website; They take marriage seriously! I would happily recommend this to any one seeking a matrimonial relationship.He was almost around the corner and I didn't even know till I checked out his profile! Now Nalini and I have known each other for few months and soon we will be inseparable."},
    { id: 3, name: 'Image 3', url: img3 ,title:"Antony and Ankavai" ,des:"JaffnaMarriage.com ஒரு பொருத்தத்தை மட்டும் கண்டுபிடிக்க உதவவில்லை; அது எங்களுக்கு சொர்க்கத்தில் செய்யப்பட்ட ஒரு சிறந்த துணையை கொண்டு வந்தது. நாங்கள் எங்கள் கனவுகளை ஒன்றாக வாழ்கிறோம், இந்த அற்புதமான வலைத்தளத்திற்கு நன்றி"},
    { id: 4, name: 'Image 4', url: img4 ,title:"Santhosh and Divya" ,des:"We chatted for months and decided to go ahead before his parents spoke to my father.By then we felt like we've known each other for ages. For a successful match-making and a happy marriage, both partners should get the opportunity to communicate effectively and set the expectations right before making a decision. We (Me and prabhu) think that, JaffnaMarriage.com is a great matchmaking site."},
    { id: 5, name: 'Image 5', url: img5 ,title:"Gowri and Jagadeesan" ,des:"எங்களுடைய காதல் மலர்ந்தது இங்கு தான் JaffnaMarriage.com ஒருவரை ஒருவர் கண்டறிந்து என்றென்றும் நிலைத்திருக்கும் அன்பை வளர்ப்பதற்கான வாய்ப்பை தந்தமைக்கு நாங்கள் நன்றிக்கடன் பட்டிருக்கிறோம்"},
];


export default imageData


// ######################### Gihan ###################################