import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { FaArrowLeft, FaBackward, FaBirthdayCake, FaFacebookMessenger, FaFemale, FaGraduationCap, FaLocationArrow, FaPhone, FaRuler, FaSpeakap, FaTimes, FaWhatsapp } from 'react-icons/fa';
import { baseURL } from '../../api';
import { userImageBASE_URL } from '../../api';
import './css/ExplorePage.css';
import { Link, useNavigate, useParams } from "react-router-dom";
import imageData from './ImageData';
import Footer from '../items/home/Footer';
import axiosInstance from '../../api'

function MatchDetailsPage() {

  const [selectuserData, selectsetUserData] = useState(null);
  const { id } = useParams();
  

  useEffect(() => {
    const getMatchById = async () => {
      try {
        let response = await fetch(
          baseURL + `/selectUserProfile/${id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        let result = await response.json();
        if (response.status === 200) {
          selectsetUserData(result.activeUserWithImagesAndDetails);
          console.log(result.activeUserWithImagesAndDetails);
        } else if (response.status === 401) {
          console.log("you are not authorized");
        } else {
          console.log("Some error occurred");
        }
      } catch (err) {
        console.log("Not authorized", err);
      }
    };
    getMatchById();
  },[id]);


    const [modalSrc, setModalSrc] = useState(null);

    const openModal = (src) => {
      setModalSrc(src);
    };
  
    const closeModal = () => {
      setModalSrc(null);
    };
  
    const handleKeyUp = (e) => {
      if (e.key === 'Escape') {
        closeModal();
      }
    };

    const [currentImage, setCurrentImage] = useState(0);
    const [noOfImages, setNoOfImages] = useState(0);
    const [mainImageSrc, setMainImageSrc] = useState('');
  
    useEffect(() => {
      if (selectuserData && selectuserData.profilePic) {
        setNoOfImages(selectuserData.images.length);
    
        // Set the initial main image source
        if (selectuserData.profilePic.length > 0) {
          setMainImageSrc(`data:image/png;base64,${selectuserData.profilePic}`);
        }
      }
    }, [selectuserData]);
  
    useEffect(() => {
      document.addEventListener('keyup', handleArrowKey);
      return () => {
        document.removeEventListener('keyup', handleArrowKey);
      };
    }, [currentImage]);
  
    const handleThumbnailClick = (index) => {
      if (selectuserData && selectuserData.images) {
        setCurrentImage(index);
      //  setMainImageSrc(`data:image/png;base64,${selectuserData.images[index]}`);
      setModalSrc(`data:image/png;base64,${selectuserData.images[index]}`);
      }
    };
  
    const handleArrowKey = (e) => {
      if (e.keyCode === 39) {
        setCurrentImage((prevImage) => (prevImage + 1) % noOfImages);
      } else if (e.keyCode === 37) {
        setCurrentImage((prevImage) => (prevImage - 1 + noOfImages) % noOfImages);
      }
    };
    
 


    return (

      <div>


<div className='openedCardName'><h1 className='user-nme'>{selectuserData?.fname} {selectuserData?.lname}</h1>
{selectuserData && (
 
   <h1 className='user-addrs'>{selectuserData.details && selectuserData.details.cityTown ? selectuserData.details.cityTown : 'Unknown'}</h1>
 
)}
</div>


                  
                  <div className='openedCard flex flex-col sm:flex-row'>
                  
     {selectuserData && selectuserData.images &&(
  <div className="gallery w-full sm:h-full md:h-1/2 p-4 left-cont">
  <div className="inner">
    <div
      className="main"
      onClick={() => openModal(mainImageSrc)}
      style={{ backgroundImage: `url(${mainImageSrc})` }}
    ></div>
    {modalSrc && (
      <div
        style={{
          background: `RGBA(0,0,0,.5) url(${modalSrc}) no-repeat center`,
          backgroundSize: 'contain',
          width: '100%',
          height: '100%',
          position: 'fixed',
          zIndex: '10000',
          top: '0',
          left: '0',
        }}
        onClick={closeModal}
        onKeyUp={handleKeyUp}
        role="button"
        tabIndex={0}
      />
    )}
    <div className="thumb-roll">
      {selectuserData.images.map((image, index) => (
        <div
          key={image.id}
          className={`thumb ${index === currentImage ? 'current' : ''}`}
          style={{ backgroundImage: `url(data:image/png;base64,${image})` }}
          onClick={() => handleThumbnailClick(index)}
        ></div>
      ))}
    </div>
  </div>
</div>
     )}            



 <div className='openedCardGeneralDetails grid grid-cols-1  md:grid-cols-2 gap-5 right-cont'>
  
  <div className=''>

  <div>
     {selectuserData &&(
         <label className='block bg-gray-200 p-0 rounded-lg label-set'><h1 className='sp-detail'>Living Country : {selectuserData.details.livingCountry}</h1></label>
           
          
     )}
         </div>

         <div>
     {selectuserData &&(
         <label className='block bg-gray-200 p-0 rounded-lg label-set'><h1 className='detail-2'>Born Country : {selectuserData.details.bornCountry}</h1></label>
           
          
     )}
         </div>

  <div>
     {selectuserData &&(
         <label className='block bg-gray-200 p-0 rounded-lg label-set'><h1 className='detail-2'>Gender : {selectuserData.details.gender}</h1></label>
           
          
     )}
         </div>

  <div >
         {selectuserData &&(
           
             <label className='block bg-gray-200 p-0 rounded-lg label-set'><h1 className='detail-2'>Spoken Language : {selectuserData.details.spokenLanguage}</h1></label>
           
         )}
     </div>
     <div >
     {selectuserData &&(

          <label className='block bg-gray-200 p-0 rounded-lg label-set'><h1 className='detail-2'>Age : {selectuserData.details.age}</h1></label>
          
     )}
         </div>
     <div>
     {selectuserData &&(

         <label className='block bg-gray-200 p-0 rounded-lg label-set'><h1 className='detail-2'>Education : {selectuserData.details.education}</h1></label>
           
           
     )}
         </div>

         <div >
     {selectuserData &&(

          <label className='block bg-gray-200 p-0 rounded-lg label-set'><h1 className='detail-2'>Work Details : {selectuserData.details.workDetails}</h1></label>
         
         
     )}
         </div>
  </div>
     
     <div className=''>
     
     <div >
     {selectuserData &&(
         
          <label className='block bg-gray-200 p-0 rounded-lg label-set'><h1 className='sp-detail'>Height : {selectuserData.details.heightInFt}</h1></label>
          

     )}
         </div>

         <div >
     {selectuserData &&(
         
          <label className='block bg-gray-200 p-0 rounded-lg label-set'><h1 className='detail-2'>Weight :  {selectuserData.details.weight}</h1></label>
          
           
     )}
         </div>

         <div >
     {selectuserData &&(
         
          <label className='block bg-gray-200 p-0 rounded-lg label-set'><h1 className='detail-2'>Phone No. : {selectuserData.details.pno}</h1></label>
           
         
     )}
         </div>

         <div >
     {selectuserData &&(
         
         <label className='block bg-gray-200 p-0 rounded-lg label-set'><h1 className='detail-2'>Email Addrerss : {selectuserData.email}</h1></label>
         
       
     )}
         </div>

         <div>
     {selectuserData &&(
         
          <label className='block bg-gray-200 p-0 rounded-lg label-set'><h1 className='detail-2'>Cast : {selectuserData.details.cast}</h1></label>
           
           
     )}
         </div>

    

         <div >
     {selectuserData &&(
          
         <label className='block bg-gray-200 p-0 rounded-lg label-set'><h1 className='detail-2'>Marital Status : {selectuserData.details.marriedStatus}</h1></label>
         
         
     )}
         </div>
     </div>
     <div className='openedCardPostedOn'>{selectuserData?.created_at}</div>

 </div>
 
 



</div>







< Footer />

      </div>
        
                



                
          
         
    )
}

export default MatchDetailsPage