import React, { useState, useEffect } from 'react';
import axios from 'axios';
import axiosInstance from '../../../../api';
import './payForm.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Payform({userdata, values, calculatedValue }) {

  const [isSubmitting, setIsSubmitting] = useState(false); 

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    // Simulate form data
    const formData = new FormData(e.target);
  
    try { 
      let newvalues;
      let newsubscriptionDetails;
      if(values.subscriptionDetails.promoCodeDiscount == 1){
         newvalues = {
          ...values,
          packageActive:1
        }
        newsubscriptionDetails = {
          ...values.subscriptionDetails,
          payment:true
        }
      }else{
         newvalues = {
          ...values,
          packageActive:0
        }
        newsubscriptionDetails = {
          ...values.subscriptionDetails,
          payment:false
        }
      }
      const userResponse = await axiosInstance.post('/insertUserDatial', newvalues);
      const userResponse3 = await axiosInstance.post('/insertUserPaymentDatial',newsubscriptionDetails);
      if(values.subscriptionDetails.promoCodeDiscount == 1){
        toast.success("Welcome to Jaffna Marriage");
        setTimeout(() => {
          window.location.href = '/browse';
        }, 2000);

      }else{
      const response = await axiosInstance.post('/process-payment', formData);
      console.log(response.data);
      const additionalFormData = new FormData();
      additionalFormData.append('custom_fields', response.data.custom_fields);
      additionalFormData.append('cms', 'PHP');
      additionalFormData.append('process_currency', 'LKR');
      additionalFormData.append('enc_method', 'JCs3J+6oSz4V0LgE0zi/Bg==');
      additionalFormData.append('secret_key', 'a1e4d692-5781-4ba9-9476-6e128b1e9739');
      additionalFormData.append('payment', response.data.payment);
      // Extend the existing FormData with the additional data
      for (const [key, value] of additionalFormData.entries()) {
        formData.append(key, value);
      }
      // Create a hidden form
      const hiddenForm = document.createElement('form');
      hiddenForm.style.display = 'none';  // Hide the form

      // Set form attributes
      hiddenForm.action = 'https://webxpay.com/index.php?route=checkout/billing';
      hiddenForm.method = 'POST';

      // Append form data as hidden input fields
      for (const [key, value] of formData.entries()) {
        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = key;
        input.value = value;
        hiddenForm.appendChild(input);
      }

      // Append the form to the body and submit it
      document.body.appendChild(hiddenForm);
      hiddenForm.submit();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false); // Reset isSubmitting to false after form submission completes
    }
      
      
  };

  return (
    <div>
    <ToastContainer />
    <div>
      <h1>Payment Details</h1>
      <form onSubmit={handleSubmit}>
        <label className='payment-label'>
          First name:<br/>
          <input className="input"  type="text" name="first_name" defaultValue={userdata.fname} />
        </label>
        <br />
        <br/>
        <label className='payment-label'>
          Last name:<br/>
          <input className="input" type="text" name="last_name" defaultValue={userdata.lname} />
        </label>
        <br />
        <br/>
        <label className='payment-label'>
          Email:<br/>
          <input className="input" type="text" name="email" defaultValue={userdata.email} />
        </label>
        <br />
        <br/>
        <label className='payment-label'>
          Contact Number:<br/>
          <input className="input" type="text" name="contact_number" defaultValue={values.pno} />
        </label>
        <br />
        <br/>
        <label className='payment-label'>
          Address Line 1:<br/>
          <input className="input" type="text" name="address_line_one" defaultValue="" placeholder='Enter Your Address Here'/>
        </label>
        <br/>
        <br/>
        {/* <br />
        <label>
          Address Line 2:
          <input type="text" name="address_line_two" defaultValue="Nawam Mawatha" />
        </label>
        <br />
        <label>
          City:
          <input type="text" name="city" defaultValue="Colombo" />
        </label>
        <br /> */}
        {/* <label>
          State:
          <input type="text" name="state" defaultValue="Western" />
        </label> */}
        {/* <br />
        <label>
          Zip/Postal Code:
          <input type="text" name="postal_code" defaultValue="10300" />
        </label>
        <br />
        <label>
          Country:
          <input type="text" name="country" defaultValue="Sri Lanka" />
        </label> */}
        <label className='payment-label'>
          Total Payment:<br/>
          <input className="input" type="text" name="tpayment" value={parseFloat(calculatedValue).toFixed(2)} />
        </label>
        <br/>
        <br/>
        <button className='button-pay shadow-2xl' type="submit" disabled={isSubmitting}>
        <span className='button-pay-span'>
  {isSubmitting ? 'Processing...' : values.subscriptionDetails.promoCodeDiscount == 1 ? 'Free' : 'Pay Now'}
</span>

        </button>
      </form>
    </div>
    </div>
  );
}

export default Payform;
