import React, { useEffect, useState } from 'react'
import '../css/ExplorePage.css';
import { FaTimes, FaFilter, FaHeart, FaLandmark, FaLocationArrow, FaSpeakap, FaBirthdayCake, FaGraduationCap, FaFemale, FaRuler, FaMale, FaRegHeart, FaInfoCircle, FaAngleDoubleLeft, FaAngleDoubleRight } from 'react-icons/fa'
import Data from './Data';


function ExplorePageDummy() {


  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;
 

  const calculateIndices = () => {
      const startIndex = (currentPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      return { startIndex, endIndex };
    };

    const totalPages = Math.ceil(Data.length / itemsPerPage);

    const handlePageChange = (newPage) => {
      setCurrentPage(newPage);
    };
    const { startIndex, endIndex } = calculateIndices();
 
    const HandleMoreDetailClick = () => {
    
      // Navigate to the specified URL
      window.location.href = '/register';
    };
  


    return(

      <div>

<div className='exploreItems'>
      {Data.slice(startIndex, endIndex).map((item, index) => (
        <div className='exploreItemCard' key={index}>
          {/* blured the image and hidden the overflow , Gihan , 24.04.2024 */}
          <div className='exploreItemImg' style={{ overflow:'hidden' }}>
            
            <img
              src={item.url}
              alt="User Profile"
              style={{ filter: 'blur(15px)' }}
            />
          </div>
          <div className='exploreItemDetails'>
            <div className='nameAndAddressForCard'>
              <div className='exploreItemName'>{item.username}</div>
              <div className='exploreItemAddress'><FaLocationArrow className='addressIcon' />{item.where} </div>
            </div>
            <div className='generalDetails'>
              <div className='eachGeneralDetail'><FaSpeakap className='gdIcon' />{item.Language}</div>
              <div className='eachGeneralDetail'><FaBirthdayCake className='gdIcon' />{item.Age}</div>
              <div className='eachGeneralDetail'><FaGraduationCap className='gdIcon' />{item.Aducation}</div>
              <div className='eachGeneralDetail'><FaFemale className='gdIcon' />{item.Gender}</div>
            </div>
            <div className='exploreItemPostedOn'></div>
            <span>
              <button className='view-btn' onClick={HandleMoreDetailClick}>
                <span className='view-txt'>More Details</span>
              </button>
            </span>
          </div>
        </div>
      ))}


     
    </div>


    {Data && Data.length > 0 && (
  <div className='pagination-container'>
      <div className="pagination-controls">


      <button className='prev-btn'
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1} 
      ><FaAngleDoubleLeft className='prv-icon' /> <span>Previous</span>
      </button>

      

      {[...Array(totalPages)].map((_, index) => (
          <button 
          key={index + 1}
          onClick={() => handlePageChange(index + 1)}
          className={currentPage === index + 1 ? 'active' : ''}
          >
          {index + 1}
          </button>
      ))}


     
      <button className='next-btn'
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === Math.ceil(Data.length / itemsPerPage)}
      >
       <span>Next</span><span><FaAngleDoubleRight className='nxt-icon'/></span> 
      </button>
      </div>
  </div>
                      )}

      </div>


    
        
    );
        
    
}


export default ExplorePageDummy;



