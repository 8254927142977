import React, { useState, useEffect  } from 'react';
import './PaymentDetails.css';
import {Button, FormControl, InputLabel, Input, Select, MenuItem,Radio, RadioGroup, FormControlLabel, TextField,} from '@mui/material';
import axiosInstance from '../../../../api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Payform from '../../home/paymentFrom/payForm';

function PaymentDetails({duration, values, updateSubscriptionDetails, updateSubscriptionDetailsbool }) {

  const [selectedPlan, setSelectedPlan] = useState(values.selectedPlans);
  const [selectedPayment, setSelectedPayment] = useState();
  const [selectedDuration, setSelectedDuration] = useState('threemonths');
  const [promoCode, setPromoCode] = useState('');
  const [discountPercentage, setPromoCodeDiscount] = useState();
  const [Packagetotal, setSubPackagetotal] = useState(4999);
  const [subtotal, setSubtotal] = useState(''); // Default to basic monthly cost
  const [discountedSubtotal, setDiscountedSubtotal] = useState();
  const [promoCodeApplied, setPromoCodeApplied] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userdata, setUserData] = useState([]);

  // Card details state
  const [cardNumber, setCardNumber] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [cvv, setCVV] = useState('');
  
  useEffect(() => {
    const fetchData = async () => {
        try {
            console.log(updateSubscriptionDetails);
            setSelectedPlan(values.selectedPlans);
            setSelectedPayment(false);
            updateSubtotal(selectedPlan, selectedDuration);

            if (duration) {
                setSelectedDuration(duration);
            }

            const userResponse = await axiosInstance.get('/validToken');
            console.log(userResponse.data.activeUserWithImagesAndDetails);
            setUserData(userResponse.data.activeUserWithImagesAndDetails);
        } catch (error) {
            console.error("Error fetching data:", error);
            // Handle the error as needed, e.g., show a message to the user
        }
    };

    fetchData(); // Call the asynchronous function

}, [duration]);


  const handlePlanChange = (event) => {
    setSelectedPlan(event.target.value);
    updateSubtotal(event.target.value, selectedDuration);
  };

  const handleDurationChange = (event) => {
    setSelectedDuration(event.target.value);
    updateSubtotal(selectedPlan, event.target.value);
    handleSubscribeauto(false);
    setPayformVisible(false);
    updateSubscriptionDetailsbool(false);
  };
  
  const planCosts = {
    basic: 4999,
    silver: 7500,
    gold: 9500,
  };

  const durationMultiplier = {
    // monthly: 1,
    threemonths: 3,
    sixMonths: 6,
    annually: 12,
  };

  const handleApplyPromoCode = () => {
    setPayformVisible(false);
    updateSubscriptionDetailsbool(false);
     if (promoCode && !isLoading) {
      setIsLoading(true);
      const currentDate = new Date().toISOString().split('T')[0];
      const values = {
       promocode: promoCode,
       packagename: selectedPlan,
       date: currentDate,
      };

      axiosInstance
        .post('/getPromotionCodeData', values)
        .then((response) => {
        
          // Handle the successful response here
          console.log('Response:', response.data);
          // You can access the specific properties from the response
          const { message, startDate, endDate, disvalue } = response.data;
          // Use the data as needed
          toast.success(message);
          console.log('Message:', message);
          console.log('Start Date:', startDate);
          console.log('End Date:', endDate);
          console.log('Discount Value:', disvalue);
          if(disvalue == 1){
            setSelectedPayment(true);
            handleSubscribeauto(true);
          }else{
            setSelectedPayment(false);
            handleSubscribeauto(false);
          }
          setPromoCodeDiscount(disvalue);
          applyPromoCode(subtotal);
          setPromoCodeApplied(true);
         
        })
        .catch((error) => {
          setSelectedPayment(false);
          toast.error(error.response.data.message);
          console.error('Error:', error.response ? error.response.data : error.message);
        }).finally(() => {
          setIsLoading(false);
   
        });
   
      }
  };
  
  const handleApplyPromoCodeReset = () => {
      handleSubscribeauto(false);
      applyPromoCode(subtotal);
      setPromoCodeApplied(false);
      setPromoCode('');
      setSelectedPayment(false);
      setPromoCodeDiscount();
      setPayformVisible(false);
      updateSubscriptionDetailsbool(false);
  };

  const updateSubtotal = (plan, duration) => {
    let realplan = '';
    if (plan === "Basic Package") {
      realplan = 'basic';
    } else if (plan === "Silver Package") {
      realplan = 'silver';
    } else if (plan === "Gold Package") {
      realplan = 'gold';
    }
    
    const newSubtotal = (parseFloat(planCosts[realplan]) || 0) * (parseFloat(durationMultiplier[duration]) || 0);
    setSubtotal(newSubtotal);
  
    // Update discounted subtotal if promo code is applied
    if (promoCodeApplied) {
      applyPromoCode(newSubtotal);
    }
  };
  

  const handlePromoCodeChange = (event) => {
    setPromoCode(event.target.value);
    setPayformVisible(false);
    updateSubscriptionDetailsbool(false);
  };

  const applyPromoCode = (amount) => {
    const discountAmount = amount * discountPercentage;
    const newDiscountedSubtotal = amount - discountAmount;
  
    setDiscountedSubtotal(isNaN(newDiscountedSubtotal) ? 0 : newDiscountedSubtotal.toFixed(2));
  };
  
  useEffect(() => {
}, [discountedSubtotal]); // Include amount in the dependency array



  const handleCardNumberChange = (event) => {
    setCardNumber(event.target.value);
  };

  const handleExpiryDateChange = (event) => {
    setExpiryDate(event.target.value);
  };

  const handleCVVChange = (event) => {
    setCVV(event.target.value);
  };
  
  const handleSubscribeauto = (paymentTureOrFalse) => {
    // Add logic to handle subscription and payment processing
    const subscriptionDetails = {
      plan: selectedPlan,
      duration: selectedDuration,
      promoCode,
      promoCodeDiscount:discountPercentage,
      subtotal: discountedSubtotal ? discountedSubtotal : subtotal,
      Packagetotal: subtotal,
      cardNumber,
      expiryDate,
      cvv,
      payment: paymentTureOrFalse
    };
  
    console.log('Subscription details:', subscriptionDetails);

    updateSubscriptionDetails(subscriptionDetails);

  };
  

  const handleSubscribe = async () => {
    // Add logic to handle subscription and payment processing
    const subscriptionDetails = {
      plan: selectedPlan,
      duration: selectedDuration,
      promoCode,
      promoCodeDiscount: discountPercentage,
      subtotal: promoCodeApplied ? discountedSubtotal : subtotal,
      Packagetotal: subtotal,
      cardNumber,
      expiryDate,
      cvv,
      payment: selectedPayment
    };

    console.log('Subscription details:', subscriptionDetails);

    updateSubscriptionDetails(subscriptionDetails);
    updateSubscriptionDetailsbool(true);
    try {
      setPayformVisible(true);
    } catch (error) {
      console.error(error);
    }
  };
  
  useEffect(() => {
    console.log(userdata);
  }, [userdata]);
  useEffect(() => {
    console.log(userdata);
  }, [values]);
  
  const getMultiplier = (duration) => {
    switch (duration) {
      case 'monthly':
        return 1;
      case 'threemonths':
        return 3;
      case 'sixMonths':
        return 6;
      case 'annually':
        return 12;
      default:
        return 1; // Set a default value if duration is not recognized
    }
  };
  
  const getMultiplierValue = (plan, selectedDuration) => {
    let realplan = '';
    if (plan === "Basic Package") {
      realplan = 'basic';
    } else if (plan === "Silver Package") {
      realplan = 'silver';
    } else if (plan === "Gold Package") {
      realplan = 'gold';
    }
    const multiplier = getMultiplier(selectedDuration);
    console.log(realplan+" "+multiplier);
    return planCosts[realplan] * multiplier;
  };

  const getselectedPlanValue = (plan) => {
     
    let realplan = '';
    if (plan === "Basic Package") {
      realplan = 'basic';
    } else if (plan === "Silver Package") {
      realplan = 'silver';
    } else if (plan === "Gold Package") {
      realplan = 'gold';
    }
    
    const newSubtotal = planCosts[realplan];
    return newSubtotal;
  };

  const [isPayformVisible, setPayformVisible] = useState(false);

  // Function to toggle dropdown visibility
  const togglePayformVisibility = () => {
    setPayformVisible(!isPayformVisible);
  };



    return (
      <div>
      <h2 className='label-ft'>Order Summary</h2>
      <div>
      <ToastContainer />
        <FormControl fullWidth >
          <label className='label-st label-st-selectedplan'>Your Select Plan is {selectedPlan}</label>
          {/* <Select
            className='select-container'
            value={selectedPlan}
            onChange={handlePlanChange}
          >
            <MenuItem value="basic" selected>{selectedPlan}</MenuItem>
          </Select> */}
        </FormControl>
      </div>
      <br></br>
      <div>
        <FormControl component="fieldset">
          <label className='label-st'>Select Your Plan Time Duration</label>
          <RadioGroup
            className='radio-container'
            value={selectedDuration}
            onChange={handleDurationChange}
          >
             {/* <FormControlLabel
              value="monthly"
              control={<Radio />}
              label="1 Months"
            /> */}
            <FormControlLabel
              value="threemonths"
              control={<Radio />}
              label="3 Months"
            />
            <FormControlLabel
              value="sixMonths"
              control={<Radio />}
              label="6 Months"
            />
            <FormControlLabel
              value="annually"
              control={<Radio />}
              label="Annually"
            />
          </RadioGroup>
        </FormControl>
      </div>

      <div className='details-container'>
        <h3 className='label-ft'>Billing Details</h3>
        <p className='label-st'>Billing Amount: {getselectedPlanValue(selectedPlan)} X {getMultiplier(selectedDuration)} <span style={{ marginLeft: '10px' }}> {getMultiplierValue(selectedPlan, selectedDuration)} LKR</span></p>
        {discountPercentage && <p> Promo Code Discount is {discountPercentage*100}%</p>
        }
       <p className='text-2xl mt-2 font-bold'>
  Total Amount: {promoCodeApplied ? 
    (parseFloat(getMultiplierValue(selectedPlan, selectedDuration)) - 
    (parseFloat(getMultiplierValue(selectedPlan, selectedDuration)) * discountPercentage)).toFixed(2) : 
    parseFloat(subtotal).toFixed(2)} LKR
</p>


      </div>

      <div className='promo-container'>
      <label className='label-st'>Promo Code</label>
        <FormControl>
          <TextField
            size='small'
            className='p-code'
            disableUnderline='true'
            type="text"
            value={promoCode}
            onChange={handlePromoCodeChange}
          />
        </FormControl>
        {!discountPercentage && <button
          className='btn-app bg-gradient-to-r from-yellow-800 to-yellow-500 text-white font-bold py-2 px-4 rounded'
          onClick={handleApplyPromoCode}
          disabled={isLoading}
        >
          {isLoading ? 'Applying...' : 'Apply'}
        </button>}
        {discountPercentage &&    <button
          className='btn-app bg-gradient-to-r from-yellow-800 to-yellow-500 text-white font-bold py-2 px-4 rounded'
          onClick={handleApplyPromoCodeReset}
        >
          Reset
        </button> }
      </div>

<button className='btn-b bg-gradient-to-r from-yellow-800 to-yellow-500 text-white font-bold py-2 px-4 rounded' onClick={handleSubscribe}>Go To Checkout</button>
{isPayformVisible && (
        <div>
          {/* Pass necessary props to Payform component */}
          <Payform
            userdata={userdata}
            values={values}
            calculatedValue={promoCodeApplied ? (getMultiplierValue(selectedPlan, selectedDuration) - (getMultiplierValue(selectedPlan, selectedDuration) * discountPercentage)) : subtotal}
          />
        </div>
      )}

    </div>
      
    

    
        );
}
export default PaymentDetails;