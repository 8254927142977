import React, { useState } from 'react';
import './Modal.css';
import { IoIosWarning } from "react-icons/io";



const Modal = ({ isOpen, onClose }) => {
    if (!isOpen) return null;

    
    const handlePackageRedirect =() =>{
      window.location.href = '/regiter-process?packageProcess=true';
    
    }
   

    return (
      <div className="modal">
        <div className="modal-content">
          <span className="close" onClick={onClose}>&times;</span>
          <div className='warning-icon-container'> 
          <IoIosWarning className='warning-icon'/>
          </div>
          <h1 className='MD-text'>You Are Not Subscribe A Package</h1>
          <p className='MD-pr1'>Pick Your Premium Package To Get More Features!</p>
          <p className='MD-pr2'>Hit The Button To Select A Premium Plan...</p>
          <div className='pkg-button-container'>
          <button className='btn-package' onClick={handlePackageRedirect}>
            <span className='btn-pkg-text'>
              Select A Plan
              </span>
              </button>
          </div>
          
        </div>
      </div>
    );
}




export default Modal;