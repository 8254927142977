import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { FaDumpster, FaEdit, FaSave } from 'react-icons/fa';
import p1 from '../../assests/home/p1.jpeg'
import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { get } from 'react-scroll/modules/mixins/scroller';
import { baseURL } from '../../api';
import { CgClose } from "react-icons/cg";
import axiosInstance from '../../api';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { FaUsersViewfinder } from "react-icons/fa6";
import profileImageNomale from '../../assests/home/profileImageNomale.jpg';
import profileImageNofemale from '../../assests/home/profileImageNofemale.jpg';
import './css/UserManagement.css';

function UserManagement() {

    const [open, setOpen] = React.useState(false);
    const [openDiactive, setOpenDiactive] = React.useState(false);
    const [openDelete, setOpenDelete] = React.useState(false);
    const [loadingUpdate, setLoadingUpdate] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [DenominationList ,setDenominationList] = useState([]);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [userList, setUserList] = useState([]);
    const [userInfo, setUserInfo] = useState({
        livingCountry: '',
        religion: '',
        cast: '',
        denomination: '',
        bornCountry: '',
        connectingJaffna: '',
        gender: '',
        marriedStatus: '',
        spokenLanguage: '',
        province: '',
        cityTown: '',
        pno: '',
        age: '',
        education: '',
        workDetails: '',
        heightInFt: '',
        weight: '',
        foodReference:'',
        termsagree: 1,
        selectedPlans: '',
        selectedSurvey: '',
      });

      const [filters, setFilters] = useState({
        id: '',
        name: '',
        email: '',
        created_at: '',
        status: ''
      });
      
      const [currentPage, setCurrentPage] = useState(1);
      const itemsPerPage = 7;
    
      const handleFilterChange = (e, field) => {
        setCurrentPage(1);
        const { value } = e.target;
        if (field === 'fname') {
          // If filtering by name, update both fname and lname filters
          setFilters({ ...filters, fname: value, lname: value });
        } else {
          // Otherwise, update the filter for the specified field
          setFilters({ ...filters, [field]: value });
        }
      };
      
      const filteredUsers = userList ? userList.filter(user => {
        return Object.keys(filters).every(key => {
          if (!filters[key]) return true;
          if (key === 'status') {
            return filters[key] === 'approved' ? user.active == 1 : user.active != 1;
          }
          // Check if filtering by name
          if (key === 'fname' || key === 'lname') {
            const fullName = `${user.fname} ${user.lname}`.toLowerCase();
            return fullName.includes(filters.fname.toLowerCase()) || fullName.includes(filters.lname.toLowerCase());
          }
          return user[key]?.toLowerCase().includes(filters[key]?.toLowerCase());
        });
      }) : [];
      
      const indexOfLastItem = currentPage * itemsPerPage;
      const indexOfFirstItem = indexOfLastItem - itemsPerPage;
      const currentItems = filteredUsers.slice(indexOfFirstItem, indexOfLastItem);

      
  const handleClickOpen = (userData) => {
    setOpen(true);
    setSelectedUser(userData);
  };
  const handleClickOpenDiactive = (userData) => {
    setOpenDiactive(true);
    setSelectedUser(userData);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenDiactive(false);
    setOpenDelete(false);
  };


  const activateUser = async() => {
    const token = localStorage.getItem('api_token');


    const response = await fetch(`${baseURL}/activateUser/${selectedUser.user_id}`, {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token
        }
    });
    

    setOpen(false);
    setOpenDiactive(false);
    sendgetUserRequest();



  }


  const deleteUser = async() => {
    const token = localStorage.getItem('api_token');


    const response = await fetch(`${baseURL}/deleteUser/${selectedUser.user_id}`, {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token
        }
    });
    

    setOpenDelete(false);
    sendgetUserRequest();
  }


  const deactivateUser = async() => {
    const token = localStorage.getItem('api_token');
    const response = await fetch(`${baseURL}/deactivateUser/` + selectedUser.user_id, {
         method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token
        }
    });
    setOpen(false);
    setOpenDiactive(false);
    sendgetUserRequest();
  }

  

    const [editPanel, setEditPanel] = useState(false);

    function openCard(userData) {
        setEditPanel(true)
        setSelectedUser(userData);
        console.log(userData)
    }

    function closeCard() {
        setEditPanel(false)

    }


    const [email, setEmail] = useState('lakshmi@mail.com');
    const [name, setName] = useState('Lakshmi Devi');
    const [country, setCountry] = useState('Sri Lnaka');
    const [city, setCity] = useState('Jaffna');
    const [relegion, seteligion] = useState('Hindu');
    const [cast, setCast] = useState('brahman');
    const [language, setLanguage] = useState('tamil');
    const [mobile, setMobile] = useState('+94761234567');
    const [gender, setGender] = useState('female');
    const [age, setAge] = useState('23');
    const [occupation, setOccupation] = useState('Doctor');
    const [marritalStatus, setMarriedStatus] = useState('Single');
    useEffect(() => {
        // fetch('http://localhost:8000/api/explore')
        // .then(res => res.json())
        // .then(data => {
        //     setExploreItems(data)
        // })


        //get all users from laravel admin route
        //https://backend.jaffnamarriage.com/Back-end/public/api/allUsers
        //pass token in header

        // fetch('https://backend.jaffnamarriage.com/Back-end/public/api/allUsers', {

        //     headers: {
        //         'Authorization': 'Bearer ' + localStorage.getItem('token')
        //     }
        // })
        //     .then(res => res.json())
        //     .then(data => {
        //         console.log(data)
        //         setUserList(data)
        //     })

        sendgetUserRequest();

        console.log('token', localStorage.getItem('api_token'))

        

    }, [])

    const sendgetUserRequest = async () => {
        const token = localStorage.getItem('api_token');
        const response = await fetch(`${baseURL}/allUsers`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token
            }
        });
        const data = await response.json();
    
        setUserList(data.users);

    }

    const userListDummy = [
        {
            id: 1,
            name: 'test user',
            gender: 'female',
            spokenLnguage: 'tamil',
            marritalStatus: 'unmarried',
            town: 'jaffna',
            country: 'sri lanka',
            age: '23',
            photo: p1,
            language: ['tamil', 'english', 'sinhala'],
            relegion: 'hindu',
            caste: 'brahmin',
            education: 'bachelor degree',
            occupation: 'doctor',
            height: '5.5ft',
            postedOn: '2021-09-01',
            postedOn: '2021-09-01',
        },
        {
            id: 2,
            name: 'test user2',
            gender: 'female',
            spokenLnguage: 'tamil',
            marritalStatus: 'unmarried',
            town: 'jaffna',
            country: 'sri lanka',
            age: '23',
            photo: p1,
            language: ['tamil', 'english', 'sinhala'],
            relegion: 'hindu',
            caste: 'brahmin',
            education: 'bachelor degree',
            occupation: 'doctor',
            height: '5.5ft',
            postedOn: '2021-09-01',
        },
        
    ]

    useEffect(() => {

        // setUserList(userListDummy);



    }, [userList])

    const handleClickgoviewuser = (item) => {
        const userProfileAdminUrl = `userProfileAdmin/${item.user_id}`;
        window.location.href = userProfileAdminUrl;
      };

   const handleClickgodeletewuser = (userData) => {
      setOpenDelete(true);
      setSelectedUser(userData);
   };  

      const updateFormValues = () => {
        setLoadingUpdate(true);
        // Log all form values
        console.log(userInfo);
  
        axiosInstance
        .post('/updateUserData', userInfo)
        .then((response) => {
          setLoadingUpdate(false);
          console.log(response);
          toast.success("Your Data Updating Success");
        })
        .catch((error) => {
          setLoadingUpdate(false);
          toast.warning("Your Data Updating not Success");
        });
  
      };


      const [cities ,setCities] = useState([]);
      const getCities = (countryName) => {
  
        // addToUserInfo('livingPlace', countryName);
    
        axios.get(`https://restcountries.com/v3.1/name/${countryName}`)
        .then((response) => {
          const country = response.data[0];
          console.log(country);
    
          const cities = country.capital;
          console.log(cities);
    
          setCities(cities);
        })
        .catch((error) => {
          console.log(error);
        });
        console.log(countryName);
    
      }

      const handleChange = (field, value) => {
        setUserInfo((prevUserInfo) => ({
          ...prevUserInfo,
          [field]: value,
        }));
      };

      const addToUserInfo = (key, value) => {
  
        //if key exists, update the value
    
        //if key does not exist, add the key and value
        setUserInfo({
          ...userInfo,
          [key]: value
        });
    
      }

      const [countryNames ,setCountryNames] = useState([]);

      useEffect(() => {
        axios.get('https://restcountries.com/v3.1/all')
        .then((response) => {
          //order by name
          const sortedCountry = response.data.sort((a, b) => a.name.common.localeCompare(b.name.common));
          console.log(sortedCountry);
    
          //get only country names
          const countryNames = sortedCountry.map((country) => country.name.common);
          console.log(countryNames);
    
          setCountryNames(countryNames);
        })
        .catch((error) => {
          console.log(error);
        });
      }, []);




      

      const casteListHindu = [
        "Adi Dravida",
        "Agamudayar",
        "Brahmin (Iyer)",
        "Chettiar",
        "Devar/Thevar/Mukkulathor",
        "Devendra Kula Vellalar",
        "Gounder",
        "Kongu Vellala Gounder",
        "Korama",
        "Kori",
        "Koshti",
        "Krishnavaka",
        "Kshatriya",
        "Kshatriya (Bhavasar)",
        "Kshatriya/Raju/Varma",
        "Kudumbi",
        "Kulal",
        "Kulalar",
        "Kulita",
        "Kumawat",
        "Kumbara",
        "Kumbhakar/Kumbhar",
        "Kumhar",
        "Kummari",
        "Kunbi",
        "Kurava",
        "Kuravan",
        "Kurmi",
        "Kurmi Kshatriya",
        "Kuruba",
        "Kuruhina Shetty",
        "Kurumbar",
        "Kurup",
        "Kushwaha",
        "Lambadi/Banjara",
        "Lambani",
        "Leva Patil",
        "Lingayath",
        "Lohana",
        "Lohar",
        "Loniya/Lonia/Lunia",
        "Lubana",
        "Madhesiya",
        "Madiga",
        "Mahajan",
        "Mahar",
        "Mahendra",
        "Maheshwari",
        "Mahindra",
        "Mahishya",
        "Majabi",
        "Mala",
        "Malayalee Variar",
        "Mali",
        "Mallah",
        "Mangalorean",
        "Maniyani",
        "Mannadiar",
        "Mannan",
        "Mapila",
        "Marar",
        "Maratha",
        "Maratha (Gomantak)",
        "Maruthuvar",
        "Marvar",
        "Marwari",
        "Matang",
        "Maurya",
        "Meda",
        "Medara",
        "Meena",
        "Meenavar",
        "Meghwal",
        "Mehra",
        "Menon",
        "Meru Darji",
        "Modak",
        "Mogaveera",
        "Monchi",
        "Mudaliar",
        "Mudaliar (Arcot)",
        "Mudaliar (Saiva)",
        "Mudaliar (Senguntha)",
        "Mudiraj",
        "Munnuru Kapu",
        "Muthuraja",
        "Naagavamsam",
        "Nadar",
        "Nagaralu",
        "Nai",
        "Naicken",
        "Naicker",
        "Naidu",
        "Naik",
        "Nair",
        "Nair (Vaniya)",
        "Nair (Velethadathu)",
        "Nair (Vilakkithala)",
        "Namasudra",
        "Nambiar",
        "Nambisan",
        "Namdev",
        "Namosudra",
        "Napit",
        "Nayak",
        "Nayaka",
        "Neeli",
        "Nhavi",
        "OBC - Barber/Naayee",
        "Oswal",
        "Otari",
        "Padmasali",
        "Panchal",
        "Pandaram",
        "Panicker",
        "Paravan",
        "Parit",
        "Parkava Kulam",
        "Partraj",
        "Pasi",
        "Paswan",
        "Patel",
        "Patel (Desai)",
        "Patel (Dodia)",
        "Patel (Kadva)",
        "Patel (Leva)",
        "Patnaick",
        "Patra",
        "Patwa",
        "Perika",
        "Pillai",
        "Pisharody",
        "Poduval",
        "Poosala",
        "Porwal",
        "Prajapati",
        "Pulaya",
        "Raigar",
        "Rajaka/Chakali/Dhobi",
        "Rajbhar",
        "Rajput",
        "Rajput (Kumaoni)",
        "Rajput (Lodhi)",
        "Ramdasia",
        "Ramgharia",
        "Rauniyar",
        "Ravidasia",
        "Rawat",
        "Reddiar",
        "Reddy",
        "Relli",
        "SSK",
        "Sadgop",
        "Sagara (Uppara)",
        "Saha",
        "Sahu",
        "Saini",
        "Saiva Vellala",
        "Saliya",
        "Sambava",
        "Satnami",
        "Savji",
        "Scheduled Caste (SC)",
        "Scheduled Tribe (ST)",
        "Senai Thalaivar",
        "Sepahia",
        "Setti Balija",
        "Shah",
        "Shilpkar",
        "Shimpi",
        "Sindhi (Bhanusali)",
        "Sindhi (Bhatia)",
        "Sindhi (Chhapru)",
        "Sindhi (Dadu)",
        "Sindhi (Hyderabadi)",
        "Sindhi (Larai)",
        "Sindhi (Lohana)",
        "Sindhi (Rohiri)",
        "Sindhi (Sehwani)",
        "Sindhi (Thatai)",
        "Sindhi-Amil",
        "Sindhi-Baibhand",
        "Sindhi-Larkana",
        "Sindhi-Sahiti",
        "Sindhi-Sakkhar",
        "Sindhi-Shikarpuri",
        "Somvanshi",
        "Sonar",
        "Soni",
        "Sozhiya Vellalar",
        "Sri Vaishnava",
        "Srisayana",
        "Subarna Banik",
        "Sugali (Naika)",
        "Sundhi",
        "Surya Balija",
        "Sutar",
        "Suthar",
        "Swakula Sali",
        "Swarnakar",
        "Tamboli",
        "Tanti",
        "Tantuway",
        "Telaga",
        "Teli",
        "Thachar",
        "Thakkar",
        "Thakur",
        "Thandan",
        "Thigala",
        "Thiyya",
        "Thuluva Vellala",
        "Tili",
        "Togata",
        "Turupu Kapu",
        "Udayar",
        "Urali Gounder",
        "Urs",
        "Vada Balija",
        "Vadagalai",
        "Vaddera",
        "Vaduka",
        "Vaish",
        "Vaish (Dhaneshawat)",
      "Vaishnav",
      "Vaishnav (Bhatia)",
      "Vaishnav (Vania)",
      "Vaishya",
      "Vallala",
      "Valluvan",
      "Valmiki",
      "Vanika Vyshya",
      "Vaniya Chettiar",
      "Vanjara",
      "Vankar",
      "Vannan",
      "Vannar",
      "Vanniyakullak Kshatriya",
      "Vanniyar",
      "Variar",
      "Varshney",
      "Veerashaiva",
      "Velaan",
      "Velama",
      "Velar",
      "Vellalar",
      "Veluthedathu (Nair)",
      "Vettuva Gounder",
      "Vishwakarma",
      "Viswabrahmin",
      "Vokkaliga",
      "Vysya",
      "Waada Balija",
      "Yadav",
      "Yellapu",
      "Other"
    ];

    const HeightList =[
      "4 feet 0 Inches",
      "4 feet 1 Inches",
      "4 feet 2 Inches",
      "4 feet 3 Inches",
      "4 feet 4 Inches",
      "4 feet 5 Inches",
      "4 feet 6 Inches",
      "4 feet 7 Inches",
      "4 feet 8 Inches",
      "4 feet 9 Inches",
      "4 feet 10 Inches",
      "4 feet 11 Inches",
      "5 feet 0 Inches",
      "5 feet 1 Inches",
      "5 feet 2 Inches",
      "5 feet 3 Inches",
      "5 feet 4 Inches",
      "5 feet 5 Inches",
      "5 feet 6 Inches",
      "5 feet 7 Inches",
      "5 feet 8 Inches",
      "5 feet 9 Inches",
      "5 feet 10 Inches",
      "5 feet 11 Inches",
      "6 feet 0 Inches",
      "6 feet 1 Inches",
      "6 feet 2 Inches",
      "6 feet 3 Inches",
      "6 feet 4 Inches",
      "6 feet 5 Inches",
    ];
    
    const castListChristian = [
      "Anglo Indian",
      "Anglican",
      "AOG",
      "Basel Mission",
      "Baptist",
      "Born Again",
      "Brethren",
      "CMS",
      "Cannonite",
      "Catholic",
      "Catholic Knanya",
      "Catholic Malankara",
      "Ceylon Pentecostal Mission (CPM)",
      "Chaldean Syrian",
      "Cheramar",
      "Church of North India (CNI)",
      "Church of South India (CSI)",
      "Convert",
      "Evangelical",
      "IPC",
      "Indian Orthodox",
      "Intercaste",
      "Jacobite",
      "Jacobite Knanya",
      "Knanaya",
      "Knanaya Catholic",
      "Knanaya Jacobite",
      "Knanaya Pentecostal",
      "Knanya",
      "Latin Catholic",
      "Malankara",
      "Malankara Catholic",
      "Manglorean",
      "Marthoma",
      "Methodist",
      "Mormon",
      "Nadar",
      "Orthodox",
      "Pentecost",
      "Presbyterian",
      "Protestant",
      "RCSC",
      "Roman Catholic",
      "Salvation Army",
      "Scheduled Caste (SC)",
      "Scheduled Tribe (ST)",
      "Seventh-day Adventist",
      "Syrian",
      "Syrian Catholic",
      "Syrian Orthodox",
      "Syro Malabar"
    ];
    
    const castListMuslim = [
      "Ansari",
      "Arain",
      "Awan",
      "Dawoodi Bohra",
      "Dekkani",
      "Dudekula",
      "Jat",
      "Khoja",
      "Lebbai",
      "Mapila",
      "Maraicar",
      "Memon",
      "Mughal",
      "Pathan",
      "Qureshi",
      "Rajput",
      "Rowther",
      "Shafi",
      "Sheikh",
      "Shia",
      "Shia Bohra",
      "Shia Imami Ismaili",
      "Shia Ithna ashariyyah",
      "Shia Zaidi",
      "Siddiqui",
      "Sunni",
      "Sunni Ehle-Hadith",
      "Sunni Hanafi",
      "Sunni Hanbali",
      "Sunni Maliki",
      "Sunni Shafi",
      "Syed"
    ];
  
    const DenominationeListChristion = [
      "Anglo Indian",
      "Anglican",
      "AOG",
      "Basel Mission",
      "Baptist",
      "Born Again",
      "Bretheren",
      "CMS",
      "Cannonite",
      "Catholic",
      "Catholic Knanya",
      "Catholic Malankara",
      "Ceylon Pentecostal Mission (CPM)",
      "Chaldean Syrian",
      "Cheramar",
      "Church of North India (CNI)",
      "Church of South India (CSI)",
      "Convert",
      "Evangelical",
      "IPC",
      "Indian Orthodox",
      "Intercaste",
      "Jacobite",
      "Jacobite Knanya",
      "Knanaya",
      "Knanaya Catholic",
      "Knanaya Jacobite",
      "Knanaya Pentecostal",
      "Knanya",
      "Latin Catholic",
      "Malankara",
      "Malankara Catholic",
      "Manglorean",
      "Marthoma",
      "Methodist",
      "Mormon",
      "Nadar",
      "Orthodox",
      "Pentecost",
      "Presbyterian",
      "Protestant",
      "RCSC",
      "Roman Catholic",
      "Salvation Army",
      "Scheduled Caste (SC)",
      "Scheduled Tribe (ST)",
      "Seventh day Adventist",
      "Syrian",
      "Syrian Catholic",
      "Syrian Orthodox",
      "Syro Malabar",
      "Other"
    ]
  
    const DenominationeListMuslim = [
      "Ansari",
      "Arain",
      "Awan",
      "Dawoodi Bohra",
      "Dekkani",
      "Dudekula",
      "Jat",
      "Khoja",
      "Lebbai",
      "Mapila",
      "Maraicar",
      "Memon",
      "Mughal",
      "Pathan",
      "Qureshi",
      "Rajput",
      "Rowther",
      "Shafi",
      "Sheikh",
      "Shia",
      "Shia Bohra",
      "Shia Imami Ismaili",
      "Shia Ithna ashariyyah",
      "Shia Zaidi",
      "Siddiqui",
      "Sunni",
      "Sunni Ehle-Hadith",
      "Sunni Hanafi",
      "Sunni Hunbali",
      "Sunni Maliki",
      "Sunni Shafi",
      "Syed",
      "Other"
    ];

    const [castList ,setCastList] = useState([]);

    const setCastAccordingToReligion = (religion) => {
  
      const religionLower = religion.toLowerCase();
  
      console.log(religionLower);
      if (religionLower === 'hindu') {
        setCastList(casteListHindu);
      } else if (religionLower === 'christianity') {
        setCastList(castListChristian);
      } else if (religionLower === 'muslim' || religionLower === 'muslim (malay)') {
        setCastList(castListMuslim);
      } else {
        setCastList([]);
      }
    }

    const setDenominationAccordingToReligion = (religion) => {

        const religionLower = religion.toLowerCase();
    
        console.log(religionLower);
        if (religionLower === 'muslim') {
          setDenominationList(DenominationeListMuslim);
        } else if (religionLower === 'christianity') {
          setDenominationList(DenominationeListChristion);
        } else {
          setDenominationList([""]);
        }
      }

      const [selectedUsers, setSelectedUsers] = useState(null);
      const [rowClick, setRowClick] = useState(true);

      const header = (
        <div style={{'textAlign':'right'}}>
            <i className="pi pi-search" style={{margin:'14px 14px 0 0'}}></i>
            <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search Here" />
            
        </div>
    );



    return (
        <div className='takeFullWithAndHeight'>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                {"Do you want to Deactivate this user?"}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                Click Confirm to Deactivate the selected user: {selectedUser && selectedUser.fname}
                    <br />
                    user id: {selectedUser && selectedUser.user_id}
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose}>Disagree</Button>
                <Button onClick={deactivateUser} autoFocus>
                    Confirm
                </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openDiactive}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                {"Do you want to Activate this user?"}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Click Confirm to Activate the selected user: {selectedUser && selectedUser.name}
                    <br />
                    user id: {selectedUser && selectedUser.user_id}
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose}>Disagree</Button>
                <Button onClick={activateUser} autoFocus>
                    Confirm
                </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openDelete}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                {"Do you want to Delete this user?"}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Click Delete to selected user: {selectedUser && selectedUser.fname}
                    <br />
                    user id: {selectedUser && selectedUser.user_id}
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose}>Disagree</Button>
                <Button onClick={deleteUser} autoFocus>
                    Confirm
                </Button>
                </DialogActions>
            </Dialog>


            {userList && (
  <div className="custom-datatable-container">
      <div className="custom-datatable-container2">
    <table className="custom-datatable">
      <thead>
        <tr>
          <th>
            key
            <input type="text" value={filters.accountKey} onChange={e => handleFilterChange(e, 'accountKey')} />
          </th>
          <th>
             Name
             <input type="text" value={filters.fname} onChange={e => handleFilterChange(e, 'fname')} />
          </th>
          <th>
            Email
            <input type="text" value={filters.email} onChange={e => handleFilterChange(e, 'email')} />
          </th>
          <th>
            Created Date
            <input type="text" value={filters.created_at} onChange={e => handleFilterChange(e, 'created_at')} />
          </th>
          <th>
            Status
            <select value={filters.status} onChange={e => handleFilterChange(e, 'status')}>
              <option value="">All</option>
              <option value="approved">Approved</option>
              <option value="not-approved">Not Approved</option>
            </select>
          </th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {currentItems.map((rowData) => (
          <tr key={rowData.id}>
            <td>{rowData.accountKey}</td>
            <td>{`${rowData.fname} ${rowData.lname}`}</td>
            <td>{rowData.email}</td>
            <td>{rowData.created_at}</td>
            <td>
              {rowData.active == 1 ? (
                <button className="status-button approved" onClick={() => handleClickOpen(rowData)}>Approved</button>
              ) : (
                <button className="status-button not-approved" onClick={() => handleClickOpenDiactive(rowData)}>Not Approved</button>
              )}
            </td>
            <td>
              <div className="iconsCont">
                <div onClick={() => openCard(rowData)} className="iconCircleEdit"><FaEdit /></div>
                <div className="iconCircleDelete" onClick={() => handleClickgodeletewuser(rowData)}><FaDumpster /></div>
                <div className='iconCircleDelete' onClick={() => handleClickgoviewuser(rowData)}><FaUsersViewfinder /></div>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
    </div>
    <div className="pagination">
        <button onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>
          Previous
        </button>
        <span>Page {currentPage}</span>
        <button onClick={() => setCurrentPage(currentPage + 1)} disabled={indexOfLastItem >= filteredUsers.length}>
          Next
        </button>
      </div>
  </div>
)}

            
            {/* {userList && (
                <div className='userList' >

                    {userList.map((item, index) => (
                        <div key={index} className='eachUserFromList'>
                            <div className='mainDetailUser'>
                            <div key={item.id} className='bigText' onClick={() => handleClickgoviewuser(item)}>
                               {item.id + " : " + item.fname + " " + item.lname}
                            </div>
                                <div>Email -{item.email}</div>
                            </div>

                            <div className='iconsCont'>
                                {(item.active == 1)? <div className='labelApproved' onClick={() => handleClickOpen(item)}>
                                    approved
                                </div> :
                                
                                <div className='labelNotApproved' onClick={() => handleClickOpenDiactive(item)}>
                                    not approved
                                    </div>
                                }
                            </div>
                            <div className='iconsCont'>

                                <div onClick={openCard} className='iconCircleEdit'><FaEdit /></div>
                                <div className='iconCircleDelete'><FaDumpster /></div>
                            </div>


                        </div>
                    ))}

                </div>
            )} */}


            {(editPanel == true) && (
                <div className='openedCardOverlay' onClick={closeCard}></div>
            )}
            {(editPanel == true) && (

                <div className='openedCardContainer'>
                                
                <div className='profEdit'>
                <div className='close-btn-container bg-gradient-to-tr from-amber-900 to-yellow-300'>
                <button className='close-btn' onClick={closeCard}>
                <CgClose />
                </button>
                
                </div>
                


                <div className="w-861 h-645">
                
                <h1 className="mb-2 text-center text-2xl font-bold bg-gray-200 rounded-xl p-2">Edit User Details</h1>
                {/* <div className='avatar'>

                <img
                  src={
                      (selectedUser.profilePic.length > 0 && selectedUser.profilePic[0]) 
                      ? `data:image/png;base64,${selectedUser.profilePic[0]}` 
                      : (selectedUser.images.length > 0 && selectedUser.images[0]) 
                      ? `data:image/png;base64,${selectedUser.images[0]}` 
                      :selectedUser.gender == 'male'
                      ? profileImageNomale
                      : profileImageNofemale
                  }
                  alt="User Profile"
                                  />

                </div> */}
                {/* user details form */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-10 full-detailcontainer">
        <div className="each-detailcontainer">
       
      
        <div className="RUserInfoDivCss">
          <div className="mb-4">
                  <label htmlFor="livingCountry" className="block text-gray-700 text-sm font-bold mb-2">
                    Living Country
                  </label>
                  <select
                    id="livingCountry"
                    name="livingCountry"
                    value={userInfo.livingCountry}
                    onChange={(e) => {getCities(e.target.value); handleChange('livingCountry', e.target.value);  addToUserInfo('livingCountry', e.target.value);}}
                    className="w-full px-4 py-3 rounded-lg bg-gray-100 border-gray-300 focus:border-blue-500 focus:bg-white focus:outline-none"
                  >
                    <option value="">{selectedUser.livingCountry ? selectedUser.livingCountry : "Select --"}</option>
                    {countryNames.map((countryName) => (
                      <option key={countryName} value={countryName}>{countryName}</option>
                    
                    ))}
                  </select>
                </div>
          </div>

          <div className="RUserInfoDivCss">
          <div className="mb-4">
                  <label htmlFor="bornCountry" className="block text-gray-700 text-sm font-bold mb-2">
                    Country (Born)
                  </label>
                  <select
                    id="bornCountry"
                    name="bornCountry"
                    value={userInfo.bornCountry}
                    onChange={(e) => {handleChange('bornCountry', e.target.value); addToUserInfo('bornCountry', e.target.value);}}
                    className="w-full px-4 py-3 rounded-lg bg-gray-100 border-gray-300 focus:border-blue-500 focus:bg-white focus:outline-none"
                  >
                    <option value="">{selectedUser.bornCountry ? selectedUser.bornCountry : "Select --"}</option>
                    {countryNames.map((countryName) => (
                      <option key={countryName} value={countryName}>{countryName}</option>
                    
                    ))}
                  </select>
                </div>
          </div>

          <div className="RUserInfoDivCss">
          <div className="mb-4">
                  <label htmlFor="province" className="block text-gray-700 text-sm font-bold mb-2">
                  Province
                  </label>
                  <input
                    id="province"
                    name="province"
                    placeholder="Enter Here"
                    defaultValue={selectedUser.province}
                    // value={userInfo.province}
                    onChange={(e) => {handleChange('province', e.target.value); addToUserInfo('province', e.target.value);}}
                    className="w-full px-4 py-3 rounded-lg bg-gray-100 border-gray-300 focus:border-blue-500 focus:bg-white focus:outline-none"
                  >
                  </input>
                </div>
          </div>

          <div className="RUserInfoDivCss">
          <div className="mb-4">
                  <label htmlFor="cityTown" className="block text-gray-700 text-sm font-bold mb-2">
                     City-Town
                  </label>
                  <input
                    id="cityTown"
                    name="cityTown"
                    placeholder="Enter Here"
                    // value={userInfo.cityTown}
                    defaultValue={selectedUser.cityTown}
                    onChange={(e) => {handleChange('cityTown', e.target.value); addToUserInfo('cityTown', e.target.value);}}
                    className="w-full px-4 py-3 rounded-lg bg-gray-100 border-gray-300 focus:border-blue-500 focus:bg-white focus:outline-none"
                  > 
                  </input>
                </div>
          </div>

          <div className="RUserInfoDivCss">
          <div className="mb-4">
                  <label htmlFor="connectingJaffna" className="block text-gray-700 text-sm font-bold mb-2">
                    How are you connecting with Jaffna?
                  </label>
                  <select
                    id="connectingJaffna"
                    name="connectingJaffna"
                    value={userInfo.bornCountryConnection}
                    onChange={(e) => {handleChange('connectingJaffna', e.target.value); addToUserInfo('connectingJaffna', e.target.value);}}
                    className="w-full px-4 py-3 rounded-lg bg-gray-100 border-gray-300 focus:border-blue-500 focus:bg-white focus:outline-none"
                  >
                    <option value="">{selectedUser.connectingJaffna ? selectedUser.connectingJaffna : "--Select"}</option>
                    
                    <option value="I was born in Jaffna.">I was born in Jaffna.</option>
<option value="My father was born in Jaffna.">My father was born in Jaffna.</option>
<option value="My mother was born in Jaffna.">My mother was born in Jaffna.</option>
<option value="Both my Parents are born in Jaffna.">Both my Parents are born in Jaffna.</option>
<option value="My Grandfather was born in Jaffna.">My Grandfather was born in Jaffna.</option>
<option value="My Grandmother was Born in Jaffna.">My Grandmother was Born in Jaffna.</option>
<option value="Both My Grandparents are born in Jaffna.">Both My Grandparents are born in Jaffna.</option>
<option value="My great Grandparents are born in Jaffna.">My great Grandparents are born in Jaffna.</option>
<option value="My Generation originated in Jaffna.">My Generation originated in Jaffna.</option>

                    
                   
                  </select>
                </div>
          </div>
         
          <div className="RUserInfoDivCss">
          <div className="mb-4">
                  <label htmlFor="religion" className="block text-gray-700 text-sm font-bold mb-2">
                    Religion
                  </label>
                  <select
                    id="religion"
                    name="religion"
                    value={userInfo.religion}
                    onChange={(e) => {setCastAccordingToReligion(e.target.value); handleChange('religion', e.target.value); setDenominationAccordingToReligion(e.target.value); addToUserInfo('religion', e.target.value);}}
                    className="w-full px-4 py-3 rounded-lg bg-gray-100 border-gray-300 focus:border-blue-500 focus:bg-white focus:outline-none"
                  >
                    <option value="">{selectedUser.religion ? selectedUser.religion : "Select --"}</option>
                    <option value="hindu">Hindu</option>
                    <option value="christianity">Christianity</option>
                    <option value="muslim">Muslim</option>
                    <option value="atheist">Atheist</option>
                    <option value="none">Not Following a Religion</option>

                  </select>
                </div>
          </div>

          <div className="RUserInfoDivCss">
          <div className="mb-4">
                  <label htmlFor="cast" className="block text-gray-700 text-sm font-bold mb-2">
                    Cast
                  </label>
                  <select
                    id="cast"
                    name="cast"
                    value={userInfo.cast}
                    onChange={(e) => {handleChange('cast', e.target.value); addToUserInfo('cast', e.target.value);}}
                    className="w-full px-4 py-3 rounded-lg bg-gray-100 border-gray-300 focus:border-blue-500 focus:bg-white focus:outline-none"
                  >
                    <option value="">{selectedUser.cast ? selectedUser.cast : "Select --"}</option>
                    {castList.map((cast) => (
                      <option key={cast} value={cast}>{cast}</option>
                    ))}
                  </select>
                </div>
          </div>

          <div className="RUserInfoDivCss">
          <div className="mb-4">
                  <label htmlFor="cast" className="block text-gray-700 text-sm font-bold mb-2">
                    Denomination
                  </label>
                  <select
                    id="denomination"
                    name="denomination"
                    value={userInfo.denomination}
                    onChange={(e) => {handleChange('denomination', e.target.value); addToUserInfo('denomination', e.target.value);}}
                    className="w-full px-4 py-3 rounded-lg bg-gray-100 border-gray-300 focus:border-blue-500 focus:bg-white focus:outline-none"
                  >
                    <option value="">{selectedUser.denomination ? selectedUser.denomination : "Select --"}</option>
                    {DenominationList.map((Denomination) => (
                      <option key={Denomination} value={Denomination}>{Denomination}</option>
                    ))}
                  </select>
                </div>
          </div>
        
          <div className="RUserInfoDivCss">
          <div className="mb-4">
                  <label htmlFor="marriedStatus" className="block text-gray-700 text-sm font-bold mb-2">
                    Married Status
                  </label>
                  <select
                    id="marriedStatus"
                    value={userInfo.marriedStatus}
                    onChange={(e) => {handleChange('marriedStatus', e.target.value); addToUserInfo('marriedStatus', e.target.value);}}
                    className="w-full px-4 py-3 rounded-lg bg-gray-100 border-gray-300 focus:border-blue-500 focus:bg-white focus:outline-none"
                  >
                    <option value="">{selectedUser.marriedStatus ? selectedUser.marriedStatus : "Select Status --"}</option>
                    <option value="single">Single</option>
                    <option value="married">Married</option>
                    <option value="divorce">Divorce</option>
                  </select>
                </div>
          </div>

          <div className="RUserInfoDivCss">
          <div className="mb-4">
                  <label htmlFor="gender" className="block text-gray-700 text-sm font-bold mb-2">
                     Gender
                  </label>
                  <select
                    id="gender"
                    name="gender"
                    value={userInfo.gender}
                    onChange={(e) => {handleChange('gender', e.target.value); addToUserInfo('gender', e.target.value);}}
                    className="w-full px-4 py-3 rounded-lg bg-gray-100 border-gray-300 focus:border-blue-500 focus:bg-white focus:outline-none"
                  >
                    <option value="">{selectedUser.gender ? selectedUser.gender : "Select --"}</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </select>
                </div>
          </div>

          <div className="RUserInfoDivCss">
          <div className="mb-4">
                  <label htmlFor="age" className="block text-gray-700 text-sm font-bold mb-2">
                    Age
                  </label>
                  <input
                    type="number"
                    id="age"
                    name="age"
                    placeholder="Enter Here"
                    // value={userInfo.age}
                    defaultValue={selectedUser.age}
                    onChange={(e) => {handleChange('age', e.target.value); addToUserInfo('age', e.target.value);}}
                    className="w-full px-4 py-3 rounded-lg bg-gray-100 border-gray-300 focus:border-blue-500 focus:bg-white focus:outline-none"
                  />
                </div>
          </div>
  
        </div>


          <div className="each-detailcontainer">

          <div className="RUserInfoDivCss">
          <div className="mb-4">
                  <label htmlFor="education" className="block text-gray-700 text-sm font-bold mb-2">
                    Education
                  </label>
                  <select
                    id="education"
                    name="education"
                    value={userInfo.education}
                    onChange={(e) => {handleChange('education', e.target.value); addToUserInfo('education', e.target.value);}}
                    className="w-full px-4 py-3 rounded-lg bg-gray-100 border-gray-300 focus:border-blue-500 focus:bg-white focus:outline-none"
                  >
                    <option value="">{selectedUser.education ? selectedUser.education : "Select --"}</option>
                    <option value="o/l">O/L</option>
                    <option value="a/l">A/L</option>
                    <option value="Diploma">Diploma</option>
                    <option value="Higher Diploma">Higher Diploma</option>
                    <option value="bachelor">Bachelor</option>
                    <option value="msc">MSc</option>
                    <option value="phd">PhD</option>
                    <option value="other">Other</option>
                  </select>
                </div>
          </div>

          <div className="RUserInfoDivCss">
          <div className="mb-4">
                  <label htmlFor="jobtitle" className="block text-gray-700 text-sm font-bold mb-2">
                  Job Title
                  </label>
                  <input
                    id="jobtitle"
                    name="jobtitle"
                    // value={userInfo.jobtitle}
                    defaultValue={selectedUser.jobtitle}
                    placeholder="Enter Here"
                    onChange={(e) => {handleChange('jobtitle', e.target.value); addToUserInfo('jobtitle', e.target.value);}}
                    className="w-full px-4 py-3 rounded-lg bg-gray-100 border-gray-300 focus:border-blue-500 focus:bg-white focus:outline-none"
                  >
                  </input>
                </div>
          </div>

          <div className="RUserInfoDivCss">
          <div className="mb-4">
                  <label htmlFor="workDetails" className="block text-gray-700 text-sm font-bold mb-2">
                    Work Details
                  </label>
                  <textarea
                    id="workDetails"
                    name="workDetails"
                    placeholder="Enter Here"
                    // value={userInfo.workDetails}
                    defaultValue={selectedUser.workDetails}
                    onChange={(e) => {handleChange('workDetails', e.target.value); addToUserInfo('workDetails', e.target.value);}}
                    className="w-full px-4 py-3 rounded-lg bg-gray-100 border-gray-300 focus:border-blue-500 focus:bg-white focus:outline-none"
                  ></textarea>
                </div>
          </div>


          <div className="RUserInfoDivCss">
          <div className="mb-4">
                  <label htmlFor="pno" className="block text-gray-700 text-sm font-bold mb-2">
                    Phone no
                  </label>
                  <input
                    type="number"
                    id="pno"
                    name="pno"
                    defaultValue={selectedUser.pno}
                    // value={userInfo.mobile}
                    placeholder="07X-XXXX-XXX"
                    onChange={(e) => {handleChange('pno', e.target.value); addToUserInfo('mobile', e.target.value);}}
                    className="w-full px-4 py-3 rounded-lg bg-gray-100 border-gray-300 focus:border-blue-500 focus:bg-white focus:outline-none"
                  />
                </div>
          </div>

          <div className="RUserInfoDivCss">
          <div className="mb-4">
                  <label htmlFor="spokenLanguage" className="block text-gray-700 text-sm font-bold mb-2">
                  Spoken Language
                  </label>
                  <select
                    id="spokenLanguage"
                    name="spokenLanguage"
                    value={userInfo.spokenLanguage}
                    onChange={(e) => {handleChange('spokenLanguage', e.target.value); addToUserInfo('spokenLanguage', e.target.value);}}
                    className="w-full px-4 py-3 rounded-lg bg-gray-100 border-gray-300 focus:border-blue-500 focus:bg-white focus:outline-none"
                  >
                    <option value="">{selectedUser.spokenLanguage ? selectedUser.spokenLanguage : "Select --"}</option>
                    <option value="tamil">Tamil</option>
                    <option value="english">English</option>
                    <option value="sinhala">Sinhala</option>
                  </select>
                </div>
          </div>
        
          
          <div className="RUserInfoDivCss">
          <div className="mb-4">
                  <label htmlFor="heightInFt" className="block text-gray-700 text-sm font-bold mb-2">
                  Height in ft
                  </label>
                    <select
                id="heightInFt"
                name="heightInFt"
                value={userInfo.HeightinFt}
                onChange={(e) => {
                  handleChange('heightInFt', e.target.value);
                  addToUserInfo('heightInFt', e.target.value);
                }}
                className="w-full px-4 py-3 rounded-lg bg-gray-100 border-gray-300 focus:border-blue-500 focus:bg-white focus:outline-none"
              >
                <option value="">{selectedUser.HeightinFt ? selectedUser.HeightinFt : "Select --"}</option>
                {HeightList.map((title, index) => (
                  <option key={index} value={title}>{title}</option>
                ))}
              </select>
                </div>
          </div>


          
          <div className="RUserInfoDivCss">
          <div className="mb-4">
                  <label htmlFor="weight" className="block text-gray-700 text-sm font-bold mb-2">
                    Weight
                  </label>
                  <input
                    type="text"
                    id="weight"
                    name="weight"
                    placeholder="Enter Here"
                    // value={userInfo.weight}
                    defaultValue={selectedUser.weight}
                    onChange={(e) => {handleChange('weight', e.target.value); addToUserInfo('weight', e.target.value);}}
                    className="w-full px-4 py-3 rounded-lg bg-gray-100 border-gray-300 focus:border-blue-500 focus:bg-white focus:outline-none"
                  />
                </div>
          </div>

          <div className="RUserInfoDivCss">
          <div className="mb-4">
                  <label htmlFor="foodReference" className="block text-gray-700 text-sm font-bold mb-2">
                  Food Preference
                  </label>
                  <select
                    id="foodReference"
                    name="foodReference"
                    value={userInfo.foodReference}
                    onChange={(e) => {handleChange('foodReference', e.target.value); addToUserInfo('foodReference', e.target.value);}}
                    className="w-full px-4 py-3 rounded-lg bg-gray-100 border-gray-300 focus:border-blue-500 focus:bg-white focus:outline-none"
                  >
                    <option value="">{selectedUser.foodReference ? selectedUser.foodReference : "Select --"}</option>
                    <option value="Vegetarian">Vegetarian</option>
                    <option value="Non-Vegetarian">Non-Vegetarian</option>
                    <option value="Vegan">Vegan</option>
                  </select>
                </div>
          </div>

          </div>
          </div>
                <div >
                <div className='save-btn-container bg-gradient-to-tr  from-amber-900 to-yellow-300 '>
                     <span className='Span-style'><FaSave /></span>
                      <button className='save-btn' onClick={updateFormValues}>             
                      <span className='Save'>{loadingUpdate ? 'Saving...' : 'Save'}</span>

                      
                      </button>
                    </div>
                </div>
                </div>
                
                
                
                
                
                </div>

                </div>
            )}

        </div>
    )
}

export default UserManagement