import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import axios from 'axios';
import ConfirmationDialog from './userProfilePicUploader';
import { FaUserCircle, FaCheckCircle } from "react-icons/fa";

function RUserInfo({ handleChange, values }) {
  const [countryNames ,setCountryNames] = useState([]);
  const [cities ,setCities] = useState([]);
  const [castList ,setCastList] = useState([]);
  const [DenominationList ,setDenominationList] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const addToUserInfo = (key, value) => {



   
    //if key exists, update the value

    //if key does not exist, add the key and value
    setUserInfo({
      ...userInfo,
      [key]: value
    });

  }
  const handleCancel = () => {
    // Add your logic here to determine whether the dialog should be closed or not
    const shouldCloseDialog = true; // Set this to true or false based on your condition

    if (shouldCloseDialog) {
      setOpenDialog(false); // Close the dialog if shouldCloseDialog is true
    }
  };



  useEffect (() => {
    console.log(userInfo);

  },[userInfo])

  const HeightList =[
    "4 Feet 0 Inches",
    "4 Feet 1 Inches",
    "4 Feet 2 Inches",
    "4 Feet 3 Inches",
    "4 Feet 4 Inches",
    "4 Feet 5 Inches",
    "4 Feet 6 Inches",
    "4 Feet 7 Inches",
    "4 Feet 8 Inches",
    "4 Feet 9 Inches",
    "4 Feet 10 Inches",
    "4 Feet 11 Inches",
    "5 Feet 0 Inches",
    "5 Feet 1 Inches",
    "5 Feet 2 Inches",
    "5 Feet 3 Inches",
    "5 Feet 4 Inches",
    "5 Feet 5 Inches",
    "5 Feet 6 Inches",
    "5 Feet 7 Inches",
    "5 Feet 8 Inches",
    "5 Feet 9 Inches",
    "5 Feet 10 Inches",
    "5 Feet 11 Inches",
    "6 Feet 0 Inches",
    "6 Feet 1 Inches",
    "6 Feet 2 Inches",
    "6 Feet 3 Inches",
    "6 Feet 4 Inches",
    "6 Feet 5 Inches",
  ];

  const SalaryRangeList = [ 
    "Below 100 USD",
    "100 USD - 150 USD",
    "200 USD - 350 USD",
    "400 USD - 550 USD",
    "1000 USD - 5000 USD",
    "5000 USD - 10000 USD",
    "Above 10000 USD",
  ];

  const JobTitleList = [
    "Wholesaler",
    "WHS Specialist",
    "WIC Nutritionist",
    "Wildlife Biologist",
    "Wildlife Technician",
    "Window and Door Installer",
    "Window Assembler",
    "Window Cleaner",
    "Window Installer",
    "Windows Administrator",
    "Windows Desktop Engineer",
    "Windows Engineer"
  ];

  const DenominationeListChristion = [
    "Anglo Indian",
    "Anglican",
    "AOG",
    "Basel Mission",
    "Baptist",
    "Born Again",
    "Bretheren",
    "CMS",
    "Cannonite",
    "Catholic",
    "Catholic Knanya",
    "Catholic Malankara",
    "Ceylon Pentecostal Mission (CPM)",
    "Chaldean Syrian",
    "Cheramar",
    "Church of North India (CNI)",
    "Church of South India (CSI)",
    "Convert",
    "Evangelical",
    "IPC",
    "Indian Orthodox",
    "Intercaste",
    "Jacobite",
    "Jacobite Knanya",
    "Knanaya",
    "Knanaya Catholic",
    "Knanaya Jacobite",
    "Knanaya Pentecostal",
    "Knanya",
    "Latin Catholic",
    "Malankara",
    "Malankara Catholic",
    "Manglorean",
    "Marthoma",
    "Methodist",
    "Mormon",
    "Nadar",
    "Orthodox",
    "Pentecost",
    "Presbyterian",
    "Protestant",
    "RCSC",
    "Roman Catholic",
    "Salvation Army",
    "Scheduled Caste (SC)",
    "Scheduled Tribe (ST)",
    "Seventh day Adventist",
    "Syrian",
    "Syrian Catholic",
    "Syrian Orthodox",
    "Syro Malabar",
    "Other"
  ]


  const DenominationeListMuslim = [
    "Ansari",
    "Arain",
    "Awan",
    "Dawoodi Bohra",
    "Dekkani",
    "Dudekula",
    "Jat",
    "Khoja",
    "Lebbai",
    "Mapila",
    "Maraicar",
    "Memon",
    "Mughal",
    "Pathan",
    "Qureshi",
    "Rajput",
    "Rowther",
    "Shafi",
    "Sheikh",
    "Shia",
    "Shia Bohra",
    "Shia Imami Ismaili",
    "Shia Ithna ashariyyah",
    "Shia Zaidi",
    "Siddiqui",
    "Sunni",
    "Sunni Ehle-Hadith",
    "Sunni Hanafi",
    "Sunni Hunbali",
    "Sunni Maliki",
    "Sunni Shafi",
    "Syed",
    "Other"
  ];


  const casteListHindu = [
    "Adi Dravida",
    "Agamudayar",
    "Brahmin (Iyer)",
    "Chettiar",
    "Devar/Thevar/Mukkulathor",
    "Devendra Kula Vellalar",
    "Gounder",
    "Kongu Vellala Gounder",
    "Korama",
    "Kori",
    "Koshti",
    "Krishnavaka",
    "Kshatriya",
    "Kshatriya (Bhavasar)",
    "Kshatriya/Raju/Varma",
    "Kudumbi",
    "Kulal",
    "Kulalar",
    "Kulita",
    "Kumawat",
    "Kumbara",
    "Kumbhakar/Kumbhar",
    "Kumhar",
    "Kummari",
    "Kunbi",
    "Kurava",
    "Kuravan",
    "Kurmi",
    "Kurmi Kshatriya",
    "Kuruba",
    "Kuruhina Shetty",
    "Kurumbar",
    "Kurup",
    "Kushwaha",
    "Lambadi/Banjara",
    "Lambani",
    "Leva Patil",
    "Lingayath",
    "Lohana",
    "Lohar",
    "Loniya/Lonia/Lunia",
    "Lubana",
    "Madhesiya",
    "Madiga",
    "Mahajan",
    "Mahar",
    "Mahendra",
    "Maheshwari",
    "Mahindra",
    "Mahishya",
    "Majabi",
    "Mala",
    "Malayalee Variar",
    "Mali",
    "Mallah",
    "Mangalorean",
    "Maniyani",
    "Mannadiar",
    "Mannan",
    "Mapila",
    "Marar",
    "Maratha",
    "Maratha (Gomantak)",
    "Maruthuvar",
    "Marvar",
    "Marwari",
    "Matang",
    "Maurya",
    "Meda",
    "Medara",
    "Meena",
    "Meenavar",
    "Meghwal",
    "Mehra",
    "Menon",
    "Meru Darji",
    "Modak",
    "Mogaveera",
    "Monchi",
    "Mudaliar",
    "Mudaliar (Arcot)",
    "Mudaliar (Saiva)",
    "Mudaliar (Senguntha)",
    "Mudiraj",
    "Munnuru Kapu",
    "Muthuraja",
    "Naagavamsam",
    "Nadar",
    "Nagaralu",
    "Nai",
    "Naicken",
    "Naicker",
    "Naidu",
    "Naik",
    "Nair",
    "Nair (Vaniya)",
    "Nair (Velethadathu)",
    "Nair (Vilakkithala)",
    "Namasudra",
    "Nambiar",
    "Nambisan",
    "Namdev",
    "Namosudra",
    "Napit",
    "Nayak",
    "Nayaka",
    "Neeli",
    "Nhavi",
    "OBC - Barber/Naayee",
    "Oswal",
    "Otari",
    "Padmasali",
    "Panchal",
    "Pandaram",
    "Panicker",
    "Paravan",
    "Parit",
    "Parkava Kulam",
    "Partraj",
    "Pasi",
    "Paswan",
    "Patel",
    "Patel (Desai)",
    "Patel (Dodia)",
    "Patel (Kadva)",
    "Patel (Leva)",
    "Patnaick",
    "Patra",
    "Patwa",
    "Perika",
    "Pillai",
    "Pisharody",
    "Poduval",
    "Poosala",
    "Porwal",
    "Prajapati",
    "Pulaya",
    "Raigar",
    "Rajaka/Chakali/Dhobi",
    "Rajbhar",
    "Rajput",
    "Rajput (Kumaoni)",
    "Rajput (Lodhi)",
    "Ramdasia",
    "Ramgharia",
    "Rauniyar",
    "Ravidasia",
    "Rawat",
    "Reddiar",
    "Reddy",
    "Relli",
    "SSK",
    "Sadgop",
    "Sagara (Uppara)",
    "Saha",
    "Sahu",
    "Saini",
    "Saiva Vellala",
    "Saliya",
    "Sambava",
    "Satnami",
    "Savji",
    "Scheduled Caste (SC)",
    "Scheduled Tribe (ST)",
    "Senai Thalaivar",
    "Sepahia",
    "Setti Balija",
    "Shah",
    "Shilpkar",
    "Shimpi",
    "Sindhi (Bhanusali)",
    "Sindhi (Bhatia)",
    "Sindhi (Chhapru)",
    "Sindhi (Dadu)",
    "Sindhi (Hyderabadi)",
    "Sindhi (Larai)",
    "Sindhi (Lohana)",
    "Sindhi (Rohiri)",
    "Sindhi (Sehwani)",
    "Sindhi (Thatai)",
    "Sindhi-Amil",
    "Sindhi-Baibhand",
    "Sindhi-Larkana",
    "Sindhi-Sahiti",
    "Sindhi-Sakkhar",
    "Sindhi-Shikarpuri",
    "Somvanshi",
    "Sonar",
    "Soni",
    "Sozhiya Vellalar",
    "Sri Vaishnava",
    "Srisayana",
    "Subarna Banik",
    "Sugali (Naika)",
    "Sundhi",
    "Surya Balija",
    "Sutar",
    "Suthar",
    "Swakula Sali",
    "Swarnakar",
    "Tamboli",
    "Tanti",
    "Tantuway",
    "Telaga",
    "Teli",
    "Thachar",
    "Thakkar",
    "Thakur",
    "Thandan",
    "Thigala",
    "Thiyya",
    "Thuluva Vellala",
    "Tili",
    "Togata",
    "Turupu Kapu",
    "Udayar",
    "Urali Gounder",
    "Urs",
    "Vada Balija",
    "Vadagalai",
    "Vaddera",
    "Vaduka",
    "Vaish",
    "Vaish (Dhaneshawat)",
  "Vaishnav",
  "Vaishnav (Bhatia)",
  "Vaishnav (Vania)",
  "Vaishya",
  "Vallala",
  "Valluvan",
  "Valmiki",
  "Vanika Vyshya",
  "Vaniya Chettiar",
  "Vanjara",
  "Vankar",
  "Vannan",
  "Vannar",
  "Vanniyakullak Kshatriya",
  "Vanniyar",
  "Variar",
  "Varshney",
  "Veerashaiva",
  "Velaan",
  "Velama",
  "Velar",
  "Vellalar",
  "Veluthedathu (Nair)",
  "Vettuva Gounder",
  "Vishwakarma",
  "Viswabrahmin",
  "Vokkaliga",
  "Vysya",
  "Waada Balija",
  "Yadav",
  "Yellapu",
  "Other"
];

const castListChristian = [
  "Adi Dravida",
    "Agamudayar",
    "Brahmin (Iyer)",
    "Chettiar",
    "Devar/Thevar/Mukkulathor",
    "Devendra Kula Vellalar",
    "Gounder",
    "Kongu Vellala Gounder",
    "Korama",
    "Kori",
    "Koshti",
    "Krishnavaka",
    "Kshatriya",
    "Kshatriya (Bhavasar)",
    "Kshatriya/Raju/Varma",
    "Kudumbi",
    "Kulal",
    "Kulalar",
    "Kulita",
    "Kumawat",
    "Kumbara",
    "Kumbhakar/Kumbhar",
    "Kumhar",
    "Kummari",
    "Kunbi",
    "Kurava",
    "Kuravan",
    "Kurmi",
    "Kurmi Kshatriya",
    "Kuruba",
    "Kuruhina Shetty",
    "Kurumbar",
    "Kurup",
    "Kushwaha",
    "Lambadi/Banjara",
    "Lambani",
    "Leva Patil",
    "Lingayath",
    "Lohana",
    "Lohar",
    "Loniya/Lonia/Lunia",
    "Lubana",
    "Madhesiya",
    "Madiga",
    "Mahajan",
    "Mahar",
    "Mahendra",
    "Maheshwari",
    "Mahindra",
    "Mahishya",
    "Majabi",
    "Mala",
    "Malayalee Variar",
    "Mali",
    "Mallah",
    "Mangalorean",
    "Maniyani",
    "Mannadiar",
    "Mannan",
    "Mapila",
    "Marar",
    "Maratha",
    "Maratha (Gomantak)",
    "Maruthuvar",
    "Marvar",
    "Marwari",
    "Matang",
    "Maurya",
    "Meda",
    "Medara",
    "Meena",
    "Meenavar",
    "Meghwal",
    "Mehra",
    "Menon",
    "Meru Darji",
    "Modak",
    "Mogaveera",
    "Monchi",
    "Mudaliar",
    "Mudaliar (Arcot)",
    "Mudaliar (Saiva)",
    "Mudaliar (Senguntha)",
    "Mudiraj",
    "Munnuru Kapu",
    "Muthuraja",
    "Naagavamsam",
    "Nadar",
    "Nagaralu",
    "Nai",
    "Naicken",
    "Naicker",
    "Naidu",
    "Naik",
    "Nair",
    "Nair (Vaniya)",
    "Nair (Velethadathu)",
    "Nair (Vilakkithala)",
    "Namasudra",
    "Nambiar",
    "Nambisan",
    "Namdev",
    "Namosudra",
    "Napit",
    "Nayak",
    "Nayaka",
    "Neeli",
    "Nhavi",
    "OBC - Barber/Naayee",
    "Oswal",
    "Otari",
    "Padmasali",
    "Panchal",
    "Pandaram",
    "Panicker",
    "Paravan",
    "Parit",
    "Parkava Kulam",
    "Partraj",
    "Pasi",
    "Paswan",
    "Patel",
    "Patel (Desai)",
    "Patel (Dodia)",
    "Patel (Kadva)",
    "Patel (Leva)",
    "Patnaick",
    "Patra",
    "Patwa",
    "Perika",
    "Pillai",
    "Pisharody",
    "Poduval",
    "Poosala",
    "Porwal",
    "Prajapati",
    "Pulaya",
    "Raigar",
    "Rajaka/Chakali/Dhobi",
    "Rajbhar",
    "Rajput",
    "Rajput (Kumaoni)",
    "Rajput (Lodhi)",
    "Ramdasia",
    "Ramgharia",
    "Rauniyar",
    "Ravidasia",
    "Rawat",
    "Reddiar",
    "Reddy",
    "Relli",
    "SSK",
    "Sadgop",
    "Sagara (Uppara)",
    "Saha",
    "Sahu",
    "Saini",
    "Saiva Vellala",
    "Saliya",
    "Sambava",
    "Satnami",
    "Savji",
    "Scheduled Caste (SC)",
    "Scheduled Tribe (ST)",
    "Senai Thalaivar",
    "Sepahia",
    "Setti Balija",
    "Shah",
    "Shilpkar",
    "Shimpi",
    "Sindhi (Bhanusali)",
    "Sindhi (Bhatia)",
    "Sindhi (Chhapru)",
    "Sindhi (Dadu)",
    "Sindhi (Hyderabadi)",
    "Sindhi (Larai)",
    "Sindhi (Lohana)",
    "Sindhi (Rohiri)",
    "Sindhi (Sehwani)",
    "Sindhi (Thatai)",
    "Sindhi-Amil",
    "Sindhi-Baibhand",
    "Sindhi-Larkana",
    "Sindhi-Sahiti",
    "Sindhi-Sakkhar",
    "Sindhi-Shikarpuri",
    "Somvanshi",
    "Sonar",
    "Soni",
    "Sozhiya Vellalar",
    "Sri Vaishnava",
    "Srisayana",
    "Subarna Banik",
    "Sugali (Naika)",
    "Sundhi",
    "Surya Balija",
    "Sutar",
    "Suthar",
    "Swakula Sali",
    "Swarnakar",
    "Tamboli",
    "Tanti",
    "Tantuway",
    "Telaga",
    "Teli",
    "Thachar",
    "Thakkar",
    "Thakur",
    "Thandan",
    "Thigala",
    "Thiyya",
    "Thuluva Vellala",
    "Tili",
    "Togata",
    "Turupu Kapu",
    "Udayar",
    "Urali Gounder",
    "Urs",
    "Vada Balija",
    "Vadagalai",
    "Vaddera",
    "Vaduka",
    "Vaish",
    "Vaish (Dhaneshawat)",
  "Vaishnav",
  "Vaishnav (Bhatia)",
  "Vaishnav (Vania)",
  "Vaishya",
  "Vallala",
  "Valluvan",
  "Valmiki",
  "Vanika Vyshya",
  "Vaniya Chettiar",
  "Vanjara",
  "Vankar",
  "Vannan",
  "Vannar",
  "Vanniyakullak Kshatriya",
  "Vanniyar",
  "Variar",
  "Varshney",
  "Veerashaiva",
  "Velaan",
  "Velama",
  "Velar",
  "Vellalar",
  "Veluthedathu (Nair)",
  "Vettuva Gounder",
  "Vishwakarma",
  "Viswabrahmin",
  "Vokkaliga",
  "Vysya",
  "Waada Balija",
  "Yadav",
  "Yellapu",
  "Other"
];

// const castListMuslim = [
//   "Ansari",
//   "Arain",
//   "Awan",
//   "Dawoodi Bohra",
//   "Dekkani",
//   "Dudekula",
//   "Jat",
//   "Khoja",
//   "Lebbai",
//   "Mapila",
//   "Maraicar",
//   "Memon",
//   "Mughal",
//   "Pathan",
//   "Qureshi",
//   "Rajput",
//   "Rowther",
//   "Shafi",
//   "Sheikh",
//   "Shia",
//   "Shia Bohra",
//   "Shia Imami Ismaili",
//   "Shia Ithna ashariyyah",
//   "Shia Zaidi",
//   "Siddiqui",
//   "Sunni",
//   "Sunni Ehle-Hadith",
//   "Sunni Hanafi",
//   "Sunni Hanbali",
//   "Sunni Maliki",
//   "Sunni Shafi",
//   "Syed",
//   "Other"
// ];


  useEffect(() => {
    axios.get('https://restcountries.com/v3.1/all')
    .then((response) => {
      //order by name
      const sortedCountry = response.data.sort((a, b) => a.name.common.localeCompare(b.name.common));
      console.log(sortedCountry);

      //get only country names
      const countryNames = sortedCountry.map((country) => country.name.common);
      console.log(countryNames);

      setCountryNames(countryNames);
    })
    .catch((error) => {
      console.log(error);
    });
  }, []);

  const getCities = (countryName) => {

    // addToUserInfo('livingPlace', countryName);

    axios.get(`https://restcountries.com/v3.1/name/${countryName}`)
    .then((response) => {
      const country = response.data[0];
      console.log(country);

      const cities = country.capital;
      console.log(cities);

      setCities(cities);
    })
    .catch((error) => {
      console.log(error);
    });
    console.log(countryName);

  }


  const setCastAccordingToReligion = (religion) => {

    const religionLower = religion.toLowerCase();

    console.log(religionLower);
    if (religionLower === 'hindu') {
      setCastList(casteListHindu);
    } else if (religionLower === 'christianity') {
      setCastList(castListChristian);
    // } else if (religionLower === 'muslim' || religionLower === 'muslim (malay)') {
    //   setCastList(castListMuslim);
    } else {
      setCastList([""]);
    }
  }

  const setDenominationAccordingToReligion = (religion) => {

    const religionLower = religion.toLowerCase();

    console.log(religionLower);
    if (religionLower === 'muslim') {
      setDenominationList(DenominationeListMuslim);
    } else if (religionLower === 'christianity') {
      setDenominationList(DenominationeListChristion);
    } else {
      setDenominationList([""]);
    }
  }

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const [uploadedImageUrl, setUploadedImageUrl] = useState('');
  const handleUploadedImageUrlChange = (url) => {
    setUploadedImageUrl(url);
  };



  return (
    <div className="w-861 h-645">
      <h1 className="mb-2 text-center text-2xl font-bold bg-gray-200 rounded-xl p-2">User Details</h1>
      {/* user details form */}
     

      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-10 full-detailcontainer">
        <div className="each-detailcontainer">

        <div className="RUserInfoDivCss">
          <div className="mb-4">
                  <label htmlFor="bornCountry" className="block text-gray-700 text-sm font-bold mb-2">
                    Country (Born)
                  </label>
                  <select
                    id="bornCountry"
                    name="bornCountry"
                    value={userInfo.bornCountry}
                    onChange={(e) => {handleChange('bornCountry', e.target.value); addToUserInfo('bornCountry', e.target.value);}}
                    className="w-full px-4 py-3 rounded-lg bg-gray-100 border-gray-300 focus:border-blue-500 focus:bg-white focus:outline-none"
                  >
                    <option value="">select --</option>
                    {countryNames.map((countryName) => (
                      <option key={countryName} value={countryName}>{countryName}</option>
                    
                    ))}
                  </select>
                </div>
          </div>
       
      
        <div className="RUserInfoDivCss">
          <div className="mb-4">
                  <label htmlFor="livingCountry" className="block text-gray-700 text-sm font-bold mb-2">
                    Country (Living)
                  </label>
                  <select
                    id="livingCountry"
                    name="livingCountry"
                    value={userInfo.livingCountry}
                    onChange={(e) => {getCities(e.target.value); handleChange('livingCountry', e.target.value);  addToUserInfo('livingCountry', e.target.value);}}
                    className="w-full px-4 py-3 rounded-lg bg-gray-100 border-gray-300 focus:border-blue-500 focus:bg-white focus:outline-none"
                  >
                    <option value="">select --</option>
                    {countryNames.map((countryName) => (
                      <option key={countryName} value={countryName}>{countryName}</option>
                    
                    ))}
                  </select>
                </div>
          </div>

          <div className="RUserInfoDivCss">
          <div className="mb-4">
                  <label htmlFor="province" className="block text-gray-700 text-sm font-bold mb-2">
                  Province (Living)
                  </label>
                  <input
                    id="province"
                    name="province"
                    value={userInfo.province}
                    placeholder="Enter Here"
                    onChange={(e) => {handleChange('province', e.target.value); addToUserInfo('province', e.target.value);}}
                    className="w-full px-4 py-3 rounded-lg bg-gray-100 border-gray-300 focus:border-blue-500 focus:bg-white focus:outline-none"
                  >
                  </input>
                </div>
          </div>

          <div className="RUserInfoDivCss">
          <div className="mb-4">
                  <label htmlFor="cityTown" className="block text-gray-700 text-sm font-bold mb-2">
                     City-Town (Living)
                  </label>
                  <input
                    id="cityTown"
                    name="cityTown"
                    placeholder="Enter Here"
                    value={userInfo.cityTown}
                    onChange={(e) => {handleChange('cityTown', e.target.value); addToUserInfo('cityTown', e.target.value);}}
                    className="w-full px-4 py-3 rounded-lg bg-gray-100 border-gray-300 focus:border-blue-500 focus:bg-white focus:outline-none"
                  > 
                  </input>
                </div>
          </div>

          {/* remove the country connection due to client reason 2024.04.24 Gihan */}

          {/* <div className="RUserInfoDivCss">
          <div className="mb-4">
                  <label htmlFor="connectingJaffna" className="block text-gray-700 text-sm font-bold mb-2">
                    How are you connecting with Jaffna?
                  </label>
                  <select
                    id="connectingJaffna"
                    name="connectingJaffna"
                    value={userInfo.bornCountryConnection}
                    onChange={(e) => {handleChange('connectingJaffna', e.target.value); addToUserInfo('connectingJaffna', e.target.value);}}
                    className="w-full px-4 py-3 rounded-lg bg-gray-100 border-gray-300 focus:border-blue-500 focus:bg-white focus:outline-none"
                  >
                    <option value="">select --</option>
                    
                      <option value="My parents are from there">I was born in Jaffna.</option>
                      <option value="My grand parents are from there">My father was  born  in Jaffna.</option>
                      <option value="My spouce is from there">My mother was born in Jaffna.</option>
                      <option value="My spouce is from there">Both my Parents are born in Jaffna.</option>
                      <option value="My spouce is from there">My Grandfather was born in Janna.</option>
                      <option value="My spouce is from there">My Grandmother was  Born  in Jaffna.</option>
                      <option value="My spouce is from there">Both My Grandparents are born in Jaffna.</option>
                      <option value="My spouce is from there">My great Grandparents are born in Jaffna.</option>
                      <option value="My spouce is from there">My Generation originated in Jaffna</option>
                    
                   
                  </select>
                </div>
          </div> */}
         
          <div className="RUserInfoDivCss">
          <div className="mb-4">
                  <label htmlFor="religion" className="block text-gray-700 text-sm font-bold mb-2">
                    Religion
                  </label>
                  <select
                    id="religion"
                    name="religion"
                    value={userInfo.religion}
                    onChange={(e) => {setCastAccordingToReligion(e.target.value); handleChange('religion', e.target.value); setDenominationAccordingToReligion(e.target.value); addToUserInfo('religion', e.target.value);}}
                    className="w-full px-4 py-3 rounded-lg bg-gray-100 border-gray-300 focus:border-blue-500 focus:bg-white focus:outline-none"
                  >
                    <option value="">select --</option>
                    <option value="hindu">Hindu</option>
                    <option value="christianity">Christianity</option>
                    <option value="muslim">Muslim</option>
                    <option value="atheist">Atheist</option>
                    <option value="none">Not Following a Religion</option>

                  </select>
                </div>
          </div>

          <div className="RUserInfoDivCss">
          <div className="mb-4">
                  <label htmlFor="cast" className="block text-gray-700 text-sm font-bold mb-2">
                    Cast
                  </label>
                  <select
                    id="cast"
                    name="cast"
                    value={userInfo.cast}
                    onChange={(e) => {handleChange('cast', e.target.value); addToUserInfo('cast', e.target.value);}}
                    className="w-full px-4 py-3 rounded-lg bg-gray-100 border-gray-300 focus:border-blue-500 focus:bg-white focus:outline-none"
                  >
                    <option value="">select --</option>
                    {castList.map((cast) => (
                      <option key={cast} value={cast}>{cast}</option>
                    ))}
                  </select>
                </div>
          </div>

          <div className="RUserInfoDivCss">
          <div className="mb-4">
                  <label htmlFor="cast" className="block text-gray-700 text-sm font-bold mb-2">
                    Denomination
                  </label>
                  <select
                    id="denomination"
                    name="denomination"
                    value={userInfo.denomination}
                    onChange={(e) => {handleChange('denomination', e.target.value); addToUserInfo('denomination', e.target.value);}}
                    className="w-full px-4 py-3 rounded-lg bg-gray-100 border-gray-300 focus:border-blue-500 focus:bg-white focus:outline-none"
                  >
                    <option value="">select --</option>
                    {DenominationList.map((Denomination) => (
                      <option key={Denomination} value={Denomination}>{Denomination}</option>
                    ))}
                  </select>
                </div>
          </div>
        
          <div className="RUserInfoDivCss">
          <div className="mb-4">
                  <label htmlFor="marriedStatus" className="block text-gray-700 text-sm font-bold mb-2">
                    Married Status
                  </label>
                  <select
                    id="marriedStatus"
                    value={userInfo.marriedStatus}
                    onChange={(e) => {handleChange('marriedStatus', e.target.value); addToUserInfo('marriedStatus', e.target.value);}}
                    className="w-full px-4 py-3 rounded-lg bg-gray-100 border-gray-300 focus:border-blue-500 focus:bg-white focus:outline-none"
                  >
                    <option value="">Select Status</option>
                    <option value="single">Single</option>
                    <option value="married">Married</option>
                    <option value="divorce">Divorce</option>
                  </select>
                </div>
          </div>

          <div className="RUserInfoDivCss">
          <div className="mb-4">
                  <label htmlFor="gender" className="block text-gray-700 text-sm font-bold mb-2">
                     Gender
                  </label>
                  <select
                    id="gender"
                    name="gender"
                    value={userInfo.gender}
                    onChange={(e) => {handleChange('gender', e.target.value); addToUserInfo('gender', e.target.value);}}
                    className="w-full px-4 py-3 rounded-lg bg-gray-100 border-gray-300 focus:border-blue-500 focus:bg-white focus:outline-none"
                  >
                    <option value="">select --</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </select>
                </div>
          </div>

          <div className="RUserInfoDivCss">
          <div className="mb-4">
                  <label htmlFor="age" className="block text-gray-700 text-sm font-bold mb-2">
                    Age
                  </label>
                  <input
                    // type="number"
                    id="age"
                    name="age"
                    placeholder="Enter Here"
                    value={userInfo.age}
                    onChange={(e) => {handleChange('age', e.target.value); addToUserInfo('age', e.target.value);}}
                    className="w-full px-4 py-3 rounded-lg bg-gray-100 border-gray-300 focus:border-blue-500 focus:bg-white focus:outline-none"
                  />
                </div>
          </div>
  
        </div>


          <div className="each-detailcontainer">

          <div className="RUserInfoDivCss">
          <div className="mb-4">
                  <label htmlFor="education" className="block text-gray-700 text-sm font-bold mb-2">
                    Education
                  </label>
                  <select
                    id="education"
                    name="education"
                    value={userInfo.education}
                    onChange={(e) => {handleChange('education', e.target.value); addToUserInfo('education', e.target.value);}}
                    className="w-full px-4 py-3 rounded-lg bg-gray-100 border-gray-300 focus:border-blue-500 focus:bg-white focus:outline-none"
                  >
                    <option value="">select --</option>
                    <option value="o/l">O/L</option>
                    <option value="a/l">A/L</option>
                    <option value="Diploma">Diploma</option>
                    <option value="Higher Diploma">Higher Diploma</option>
                    <option value="bachelor">Bachelor</option>
                    <option value="msc">MSc</option>
                    <option value="phd">PhD</option>
                    <option value="other">Other</option>
                  </select>
                </div>
          </div>

          <div className="RUserInfoDivCss">
            <div className="mb-4">
              <label htmlFor="occupation" className="block text-gray-700 text-sm font-bold mb-2">
                occupation <span style={{ color:"grey" }}>(*Optional)</span>
              </label>
              <div className="mb-4">
                  <input
                    id="occupation"
                    name="occupation"
                    placeholder="Enter Here"
                    value={userInfo.occupation}
                    onChange={(e) => {handleChange('occupation', e.target.value); addToUserInfo('occupation', e.target.value);}}
                    className="w-full px-4 py-3 rounded-lg bg-gray-100 border-gray-300 focus:border-blue-500 focus:bg-white focus:outline-none"
                  ></input>
                </div>
            </div>
          </div>

          <div className="RUserInfoDivCss">
          <div className="mb-4">
                  <label htmlFor="workDetails" className="block text-gray-700 text-sm font-bold mb-2">
                    Work Details
                  </label>
                  <textarea
                    id="workDetails"
                    name="workDetails"
                    placeholder="Enter Here"
                    value={userInfo.workDetails}
                    onChange={(e) => {handleChange('workDetails', e.target.value); addToUserInfo('workDetails', e.target.value);}}
                    className="w-full px-4 py-3 rounded-lg bg-gray-100 border-gray-300 focus:border-blue-500 focus:bg-white focus:outline-none"
                  ></textarea>
                </div>
          </div>

          <div className="RUserInfoDivCss">
            <div className="mb-4">
              <label htmlFor="occupation" className="block text-gray-700 text-sm font-bold mb-2">
                Salaries
              </label>
              <select
                id="salaries"
                name="salaries"
                value={userInfo.salaries}
                onChange={(e) => {
                  handleChange('salaries', e.target.value);
                  addToUserInfo('salaries', e.target.value);
                }}
                className="w-full px-4 py-3 rounded-lg bg-gray-100 border-gray-300 focus:border-blue-500 focus:bg-white focus:outline-none"
              >
                <option value="">select --</option>
                {SalaryRangeList.map((title, index) => (
                  <option key={index} value={title}>{title}</option>
                ))}
              </select>
            </div>
          </div>


          <div className="RUserInfoDivCss">
          <div className="mb-4">
                  <label htmlFor="pno" className="block text-gray-700 text-sm font-bold mb-2">
                    Phone no
                  </label>
                  <input
                    id="pno"
                    name="pno"
                    value={userInfo.mobile}
                    placeholder="07X-XXXX-XXX"
                    onChange={(e) => {handleChange('pno', e.target.value); addToUserInfo('mobile', e.target.value);}}
                    className="w-full px-4 py-3 rounded-lg bg-gray-100 border-gray-300 focus:border-blue-500 focus:bg-white focus:outline-none"
                  />
                </div>
          </div>

          <div className="RUserInfoDivCss">
          <div className="mb-4">
                  <label htmlFor="spokenLanguage" className="block text-gray-700 text-sm font-bold mb-2">
                  Spoken Language
                  </label>
                  <select
                    id="spokenLanguage"
                    name="spokenLanguage"
                    value={userInfo.spokenLanguage}
                    onChange={(e) => {handleChange('spokenLanguage', e.target.value); addToUserInfo('spokenLanguage', e.target.value);}}
                    className="w-full px-4 py-3 rounded-lg bg-gray-100 border-gray-300 focus:border-blue-500 focus:bg-white focus:outline-none"
                  >
                    <option value="">select --</option>
                    <option value="tamil">Tamil</option>
                    <option value="english">English</option>
                    <option value="sinhala">Sinhala</option>
                  </select>
                </div>
          </div>
        
          <div className="RUserInfoDivCss">
          <div className="mb-4">
                  <label htmlFor="heightInFt" className="block text-gray-700 text-sm font-bold mb-2">
                    Height in ft
                  </label>
                    <select
                id="heightInFt"
                name="heightInFt"
                value={userInfo.HeightinFt}
                onChange={(e) => {
                  handleChange('heightInFt', e.target.value);
                  addToUserInfo('heightInFt', e.target.value);
                }}
                className="w-full px-4 py-3 rounded-lg bg-gray-100 border-gray-300 focus:border-blue-500 focus:bg-white focus:outline-none"
              >
                <option value="">select --</option>
                {HeightList.map((title, index) => (
                  <option key={index} value={title}>{title}</option>
                ))}
              </select>
                </div>
          </div>
          <div className="RUserInfoDivCss">
          <div className="mb-4">
                  <label htmlFor="weight" className="block text-gray-700 text-sm font-bold mb-2">
                    Weight
                  </label>
                  <input
                    id="weight"
                    name="weight"
                    placeholder="Enter Here"
                    value={userInfo.weight}
                    onChange={(e) => {handleChange('weight', e.target.value); addToUserInfo('weight', e.target.value);}}
                    className="w-full px-4 py-3 rounded-lg bg-gray-100 border-gray-300 focus:border-blue-500 focus:bg-white focus:outline-none"
                  />
                </div>
          </div>

          <div className="RUserInfoDivCss">
          <div className="mb-4">
                  <label htmlFor="foodReference" className="block text-gray-700 text-sm font-bold mb-2">
                  Food Preference
                  </label>
                  <select
                    id="foodReference"
                    name="foodReference"
                    value={userInfo.foodReference}
                    onChange={(e) => {handleChange('foodReference', e.target.value); addToUserInfo('foodReference', e.target.value);}}
                    className="w-full px-4 py-3 rounded-lg bg-gray-100 border-gray-300 focus:border-blue-500 focus:bg-white focus:outline-none"
                  >
                    <option value="">select --</option>
                    <option value="Vegetarian">Vegetarian</option>
                    <option value="Non-Vegetarian">Non-Vegetarian</option>
                    <option value="Vegan">Vegan</option>
                  </select>
                </div>
          </div>
          <div className="RUserInfoDivCss mb-4" style={{ display: 'flex', alignItems: 'center' }}>
          <button className="profilepic-upload-button" onClick={handleOpenDialog} style={{ position: 'relative' }}>
  {uploadedImageUrl ? (
    <>
      <FaCheckCircle
        className="done-icon user-profileicon"
        style={{
          color: 'white',
        }}
      />
      <span>Change</span>
    </>
  ) : (
    <>
      <FaUserCircle className="user-profileicon" />
      <span>Upload Photo Here</span>
    </>
  )}
</button>


      <ConfirmationDialog
        open={openDialog}
        onClose={handleCancel}
        onUploadedImageUrlChange={handleUploadedImageUrlChange}
        uploadedImageUrlnew={uploadedImageUrl}
      />
    </div>
          </div>
          
        
      </div>
    </div>
  );
  
}

export default RUserInfo;
