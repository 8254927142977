import React from 'react'
import { useEffect } from 'react';
import { useState, useRef } from 'react';
import { FaArrowLeft, FaBirthdayCake, FaEdit, FaFemale, FaGraduationCap, FaLocationArrow, FaRuler, FaSpeakap, FaWindowClose, FaSave, FaHeart} from 'react-icons/fa';
import { FaRegEdit } from "react-icons/fa";
import { LuLogOut } from "react-icons/lu";
import { FiPlus } from "react-icons/fi";
import { BsFillSave2Fill } from "react-icons/bs";
import { CgClose } from "react-icons/cg";
import { MdOutlineSaveAlt } from "react-icons/md";
import { MdSaveAlt } from "react-icons/md";
import { baseURL } from '../../../api';
import { Link, useNavigate, useParams } from "react-router-dom";
// import p1 from '../../assests/home/p1.jpeg';
import { Button } from '@mui/material';
import Footer from '../../items/home/Footer';
import { userImageBASE_URL } from '../../../api';
import MuiAlert from '@mui/material/Alert';
import profileImageNofemale from "../../../../src/assests/home/profileImageNofemale.jpg";
import profileImageNomale from "../../../../src/assests/home/profileImageNomale.jpg";
import ConfirmationDialog from '../userProfilePicUploadToProfilePage'
import '../../../App.css';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import axios from 'axios';
import './ProfilePage.css';
import './w3.css';
import axiosInstance from '../../../api';
import ResponsiveDialog from '../../items/alert';
import Form from 'react-bootstrap/Form';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  
  });
function ProfilePage() {

    


    // const [userData, setUserData] = useState(null);
    const [editPanel, setEditPanel] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [country, setCountry] = useState('');
    const [city, setCity] = useState('');
    const [relegion, seteligion] = useState('');
    const [cast, setCast] = useState('');
    const [language, setLanguage] = useState('');
    const [mobile, setMobile] = useState('');
    const [gender, setGender] = useState('');
    const [age, setAge] = useState('');
    const [occupation, setOccupation] = useState('');
    const [marritalStatus, setMarriedStatus] = useState('');
    const [matchData, setMatchData] = useState(null);
    const [loading, setLoading] = useState(true);
    const { id } = useParams();
    const [openDialog, setOpenDialog] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [setSelectedimg, setSelectedImage] = useState('');
    const [DenominationList ,setDenominationList] = useState([]);
    const [loadingUpdate, setLoadingUpdate] = useState(false);
    const [userInfo, setUserInfo] = useState({
      livingCountry: '',
      religion: '',
      cast: '',
      denomination: '',
      bornCountry: '',
      connectingJaffna: '',
      gender: '',
      marriedStatus: '',
      spokenLanguage: '',
      province: '',
      cityTown: '',
      pno: '',
      age: '',
      education: '',
      workDetails: '',
      heightInFt: '',
      weight: '',
      foodReference:'',
      termsagree: 1,
      selectedPlans: '',
      selectedSurvey: '',
      
    });
    
    const navigate = useNavigate();

  const handleButtonClickImagesUploads = () => {
    navigate('/userImagesUploader');
  };
  
    const handleUpdateUserInfo = () => {
      // Here you can perform an action to send userInfo to the server for an update
      console.log('Updated user info:', userInfo);
      // Perform API call or update logic using userInfo
    };
  
    const handleChange = (field, value) => {
      setUserInfo((prevUserInfo) => ({
        ...prevUserInfo,
        [field]: value,
      }));
    };
    
    const updateFormValues = () => {
      setLoadingUpdate(true);
      // Log all form values
      console.log(userInfo);

      axiosInstance
      .post('/updateUserData', userInfo)
      .then((response) => {
        setLoadingUpdate(false);
        console.log(response);
        toast.success("Your Data Updating Success");
      })
      .catch((error) => {
        setLoadingUpdate(false);
        toast.warning("Your Data Updating not Success");
      });

    };

    const [countryNames ,setCountryNames] = useState([]);
    const [cities ,setCities] = useState([]);
    const [castList ,setCastList] = useState([]);
  
    const addToUserInfo = (key, value) => {
  
      //if key exists, update the value
  
      //if key does not exist, add the key and value
      setUserInfo({
        ...userInfo,
        [key]: value
      });
  
    }
   
    const handleLogout = () => {
      setAnchorEl(null);
      // Handle logout logic
      setDialogOpen(true);
    };

    const handleCloseDialog = () => {
      setDialogOpen(false);
      // Optionally, you can perform any additional logic after closing the dialog
      // For example, you can navigate to another page or execute specific actions
    };
  
    const logout = () => {

      // Make a POST request to the logout endpoint
      axiosInstance.get('/logout')
        .then(response => {
          // Handle the response
          console.log(response.data); // Assuming the response contains a 'message' field
          // Handle logout logic
          toast.success('Logout successful');
          localStorage.removeItem('login');
          localStorage.removeItem('api_token');
          localStorage.removeItem('logiRole');
          handleCloseDialog();
          window.location.href = '/';
        })
        .catch(error => {
          // Handle the error
          console.error(error);
  
          // Perform any error handling or display error messages to the user
        });
  
  
    };

  

    const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    console.log('Selected File:', file);
  };

  const handleButtonClick = () => {
    // Trigger the file input click
    fileInputRef.current.click();
  };
  
  const [isDialogOpen, setDialogOpenImagesUpload ] = useState(false);

  const handlePlusClickImagesUpload  = () => {
    setDialogOpenImagesUpload(true);
  };

  const handleCloseDialogImagesUpload = () => {
    setDialogOpenImagesUpload(false);
  };
  
    useEffect (() => {
      console.log(userInfo);
  
    },[userInfo])

    const HeightList =[
      "4 feet 0 Inches",
      "4 feet 1 Inches",
      "4 feet 2 Inches",
      "4 feet 3 Inches",
      "4 feet 4 Inches",
      "4 feet 5 Inches",
      "4 feet 6 Inches",
      "4 feet 7 Inches",
      "4 feet 8 Inches",
      "4 feet 9 Inches",
      "4 feet 10 Inches",
      "4 feet 11 Inches",
      "5 feet 0 Inches",
      "5 feet 1 Inches",
      "5 feet 2 Inches",
      "5 feet 3 Inches",
      "5 feet 4 Inches",
      "5 feet 5 Inches",
      "5 feet 6 Inches",
      "5 feet 7 Inches",
      "5 feet 8 Inches",
      "5 feet 9 Inches",
      "5 feet 10 Inches",
      "5 feet 11 Inches",
      "6 feet 0 Inches",
      "6 feet 1 Inches",
      "6 feet 2 Inches",
      "6 feet 3 Inches",
      "6 feet 4 Inches",
      "6 feet 5 Inches",
    ];


    const casteListHindu = [
      "Adi Dravida",
      "Agamudayar",
      "Brahmin (Iyer)",
      "Chettiar",
      "Devar/Thevar/Mukkulathor",
      "Devendra Kula Vellalar",
      "Gounder",
      "Kongu Vellala Gounder",
      "Korama",
      "Kori",
      "Koshti",
      "Krishnavaka",
      "Kshatriya",
      "Kshatriya (Bhavasar)",
      "Kshatriya/Raju/Varma",
      "Kudumbi",
      "Kulal",
      "Kulalar",
      "Kulita",
      "Kumawat",
      "Kumbara",
      "Kumbhakar/Kumbhar",
      "Kumhar",
      "Kummari",
      "Kunbi",
      "Kurava",
      "Kuravan",
      "Kurmi",
      "Kurmi Kshatriya",
      "Kuruba",
      "Kuruhina Shetty",
      "Kurumbar",
      "Kurup",
      "Kushwaha",
      "Lambadi/Banjara",
      "Lambani",
      "Leva Patil",
      "Lingayath",
      "Lohana",
      "Lohar",
      "Loniya/Lonia/Lunia",
      "Lubana",
      "Madhesiya",
      "Madiga",
      "Mahajan",
      "Mahar",
      "Mahendra",
      "Maheshwari",
      "Mahindra",
      "Mahishya",
      "Majabi",
      "Mala",
      "Malayalee Variar",
      "Mali",
      "Mallah",
      "Mangalorean",
      "Maniyani",
      "Mannadiar",
      "Mannan",
      "Mapila",
      "Marar",
      "Maratha",
      "Maratha (Gomantak)",
      "Maruthuvar",
      "Marvar",
      "Marwari",
      "Matang",
      "Maurya",
      "Meda",
      "Medara",
      "Meena",
      "Meenavar",
      "Meghwal",
      "Mehra",
      "Menon",
      "Meru Darji",
      "Modak",
      "Mogaveera",
      "Monchi",
      "Mudaliar",
      "Mudaliar (Arcot)",
      "Mudaliar (Saiva)",
      "Mudaliar (Senguntha)",
      "Mudiraj",
      "Munnuru Kapu",
      "Muthuraja",
      "Naagavamsam",
      "Nadar",
      "Nagaralu",
      "Nai",
      "Naicken",
      "Naicker",
      "Naidu",
      "Naik",
      "Nair",
      "Nair (Vaniya)",
      "Nair (Velethadathu)",
      "Nair (Vilakkithala)",
      "Namasudra",
      "Nambiar",
      "Nambisan",
      "Namdev",
      "Namosudra",
      "Napit",
      "Nayak",
      "Nayaka",
      "Neeli",
      "Nhavi",
      "OBC - Barber/Naayee",
      "Oswal",
      "Otari",
      "Padmasali",
      "Panchal",
      "Pandaram",
      "Panicker",
      "Paravan",
      "Parit",
      "Parkava Kulam",
      "Partraj",
      "Pasi",
      "Paswan",
      "Patel",
      "Patel (Desai)",
      "Patel (Dodia)",
      "Patel (Kadva)",
      "Patel (Leva)",
      "Patnaick",
      "Patra",
      "Patwa",
      "Perika",
      "Pillai",
      "Pisharody",
      "Poduval",
      "Poosala",
      "Porwal",
      "Prajapati",
      "Pulaya",
      "Raigar",
      "Rajaka/Chakali/Dhobi",
      "Rajbhar",
      "Rajput",
      "Rajput (Kumaoni)",
      "Rajput (Lodhi)",
      "Ramdasia",
      "Ramgharia",
      "Rauniyar",
      "Ravidasia",
      "Rawat",
      "Reddiar",
      "Reddy",
      "Relli",
      "SSK",
      "Sadgop",
      "Sagara (Uppara)",
      "Saha",
      "Sahu",
      "Saini",
      "Saiva Vellala",
      "Saliya",
      "Sambava",
      "Satnami",
      "Savji",
      "Scheduled Caste (SC)",
      "Scheduled Tribe (ST)",
      "Senai Thalaivar",
      "Sepahia",
      "Setti Balija",
      "Shah",
      "Shilpkar",
      "Shimpi",
      "Sindhi (Bhanusali)",
      "Sindhi (Bhatia)",
      "Sindhi (Chhapru)",
      "Sindhi (Dadu)",
      "Sindhi (Hyderabadi)",
      "Sindhi (Larai)",
      "Sindhi (Lohana)",
      "Sindhi (Rohiri)",
      "Sindhi (Sehwani)",
      "Sindhi (Thatai)",
      "Sindhi-Amil",
      "Sindhi-Baibhand",
      "Sindhi-Larkana",
      "Sindhi-Sahiti",
      "Sindhi-Sakkhar",
      "Sindhi-Shikarpuri",
      "Somvanshi",
      "Sonar",
      "Soni",
      "Sozhiya Vellalar",
      "Sri Vaishnava",
      "Srisayana",
      "Subarna Banik",
      "Sugali (Naika)",
      "Sundhi",
      "Surya Balija",
      "Sutar",
      "Suthar",
      "Swakula Sali",
      "Swarnakar",
      "Tamboli",
      "Tanti",
      "Tantuway",
      "Telaga",
      "Teli",
      "Thachar",
      "Thakkar",
      "Thakur",
      "Thandan",
      "Thigala",
      "Thiyya",
      "Thuluva Vellala",
      "Tili",
      "Togata",
      "Turupu Kapu",
      "Udayar",
      "Urali Gounder",
      "Urs",
      "Vada Balija",
      "Vadagalai",
      "Vaddera",
      "Vaduka",
      "Vaish",
      "Vaish (Dhaneshawat)",
    "Vaishnav",
    "Vaishnav (Bhatia)",
    "Vaishnav (Vania)",
    "Vaishya",
    "Vallala",
    "Valluvan",
    "Valmiki",
    "Vanika Vyshya",
    "Vaniya Chettiar",
    "Vanjara",
    "Vankar",
    "Vannan",
    "Vannar",
    "Vanniyakullak Kshatriya",
    "Vanniyar",
    "Variar",
    "Varshney",
    "Veerashaiva",
    "Velaan",
    "Velama",
    "Velar",
    "Vellalar",
    "Veluthedathu (Nair)",
    "Vettuva Gounder",
    "Vishwakarma",
    "Viswabrahmin",
    "Vokkaliga",
    "Vysya",
    "Waada Balija",
    "Yadav",
    "Yellapu",
    "Other"
  ];
  
  const castListChristian = [
    "Anglo Indian",
    "Anglican",
    "AOG",
    "Basel Mission",
    "Baptist",
    "Born Again",
    "Brethren",
    "CMS",
    "Cannonite",
    "Catholic",
    "Catholic Knanya",
    "Catholic Malankara",
    "Ceylon Pentecostal Mission (CPM)",
    "Chaldean Syrian",
    "Cheramar",
    "Church of North India (CNI)",
    "Church of South India (CSI)",
    "Convert",
    "Evangelical",
    "IPC",
    "Indian Orthodox",
    "Intercaste",
    "Jacobite",
    "Jacobite Knanya",
    "Knanaya",
    "Knanaya Catholic",
    "Knanaya Jacobite",
    "Knanaya Pentecostal",
    "Knanya",
    "Latin Catholic",
    "Malankara",
    "Malankara Catholic",
    "Manglorean",
    "Marthoma",
    "Methodist",
    "Mormon",
    "Nadar",
    "Orthodox",
    "Pentecost",
    "Presbyterian",
    "Protestant",
    "RCSC",
    "Roman Catholic",
    "Salvation Army",
    "Scheduled Caste (SC)",
    "Scheduled Tribe (ST)",
    "Seventh-day Adventist",
    "Syrian",
    "Syrian Catholic",
    "Syrian Orthodox",
    "Syro Malabar"
  ];
  
  const castListMuslim = [
    "Ansari",
    "Arain",
    "Awan",
    "Dawoodi Bohra",
    "Dekkani",
    "Dudekula",
    "Jat",
    "Khoja",
    "Lebbai",
    "Mapila",
    "Maraicar",
    "Memon",
    "Mughal",
    "Pathan",
    "Qureshi",
    "Rajput",
    "Rowther",
    "Shafi",
    "Sheikh",
    "Shia",
    "Shia Bohra",
    "Shia Imami Ismaili",
    "Shia Ithna ashariyyah",
    "Shia Zaidi",
    "Siddiqui",
    "Sunni",
    "Sunni Ehle-Hadith",
    "Sunni Hanafi",
    "Sunni Hanbali",
    "Sunni Maliki",
    "Sunni Shafi",
    "Syed"
  ];

  const DenominationeListChristion = [
    "Anglo Indian",
    "Anglican",
    "AOG",
    "Basel Mission",
    "Baptist",
    "Born Again",
    "Bretheren",
    "CMS",
    "Cannonite",
    "Catholic",
    "Catholic Knanya",
    "Catholic Malankara",
    "Ceylon Pentecostal Mission (CPM)",
    "Chaldean Syrian",
    "Cheramar",
    "Church of North India (CNI)",
    "Church of South India (CSI)",
    "Convert",
    "Evangelical",
    "IPC",
    "Indian Orthodox",
    "Intercaste",
    "Jacobite",
    "Jacobite Knanya",
    "Knanaya",
    "Knanaya Catholic",
    "Knanaya Jacobite",
    "Knanaya Pentecostal",
    "Knanya",
    "Latin Catholic",
    "Malankara",
    "Malankara Catholic",
    "Manglorean",
    "Marthoma",
    "Methodist",
    "Mormon",
    "Nadar",
    "Orthodox",
    "Pentecost",
    "Presbyterian",
    "Protestant",
    "RCSC",
    "Roman Catholic",
    "Salvation Army",
    "Scheduled Caste (SC)",
    "Scheduled Tribe (ST)",
    "Seventh day Adventist",
    "Syrian",
    "Syrian Catholic",
    "Syrian Orthodox",
    "Syro Malabar",
    "Other"
  ]

  const DenominationeListMuslim = [
    "Ansari",
    "Arain",
    "Awan",
    "Dawoodi Bohra",
    "Dekkani",
    "Dudekula",
    "Jat",
    "Khoja",
    "Lebbai",
    "Mapila",
    "Maraicar",
    "Memon",
    "Mughal",
    "Pathan",
    "Qureshi",
    "Rajput",
    "Rowther",
    "Shafi",
    "Sheikh",
    "Shia",
    "Shia Bohra",
    "Shia Imami Ismaili",
    "Shia Ithna ashariyyah",
    "Shia Zaidi",
    "Siddiqui",
    "Sunni",
    "Sunni Ehle-Hadith",
    "Sunni Hanafi",
    "Sunni Hunbali",
    "Sunni Maliki",
    "Sunni Shafi",
    "Syed",
    "Other"
  ];
  
  
    useEffect(() => {
      axios.get('https://restcountries.com/v3.1/all')
      .then((response) => {
        //order by name
        const sortedCountry = response.data.sort((a, b) => a.name.common.localeCompare(b.name.common));
        console.log(sortedCountry);
  
        //get only country names
        const countryNames = sortedCountry.map((country) => country.name.common);
        console.log(countryNames);
  
        setCountryNames(countryNames);
      })
      .catch((error) => {
        console.log(error);
      });
    }, []);
  
    const getCities = (countryName) => {
  
      // addToUserInfo('livingPlace', countryName);
  
      axios.get(`https://restcountries.com/v3.1/name/${countryName}`)
      .then((response) => {
        const country = response.data[0];
        console.log(country);
  
        const cities = country.capital;
        console.log(cities);
  
        setCities(cities);
      })
      .catch((error) => {
        console.log(error);
      });
      console.log(countryName);
  
    }
    const addBornCountry = (countryName) => {
      console.log(countryName);
    }
    const addBornCountryConnection = (countryName) => {
      console.log(countryName);
    }
  
    const setCastAccordingToReligion = (religion) => {
  
      const religionLower = religion.toLowerCase();
  
      console.log(religionLower);
      if (religionLower === 'hindu') {
        setCastList(casteListHindu);
      } else if (religionLower === 'christianity') {
        setCastList(castListChristian);
      } else if (religionLower === 'muslim' || religionLower === 'muslim (malay)') {
        setCastList(castListMuslim);
      } else {
        setCastList([]);
      }
    }

    const setDenominationAccordingToReligion = (religion) => {

      const religionLower = religion.toLowerCase();
  
      console.log(religionLower);
      if (religionLower === 'muslim') {
        setDenominationList(DenominationeListMuslim);
      } else if (religionLower === 'christianity') {
        setDenominationList(DenominationeListChristion);
      } else {
        setDenominationList([""]);
      }
    }

    const handleClick = (event, imageValue) => {
      setSelectedImage(imageValue);
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const backButton = () => {
        navigate("/browse");
    };
    
    const editProfilePic = () => {
        setOpenDialog(true);
    };
    const openMoadl = () => {
        setIsModalOpen(true);
    };
      
    const closeModal = () => {
        setIsModalOpen(false);
    };
      
    useEffect(() => {
        const getMatchById = async () => {
          try {
            let response = await fetch(
                baseURL+`/selectUserProfile/${id}`,
              {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  // 'Content-Type': 'application/x-www-form-urlencoded',
                },
              }
            );
            let result = await response.json();
            console.log(response);
            if (response.status == 200) {
              setLoading(false);
              setMatchData(result.activeUserWithImagesAndDetails);
              setEmail(result.activeUserWithImagesAndDetails.email);
              setCountry(result.activeUserWithImagesAndDetails.details.livingPlace);
              setName(result.activeUserWithImagesAndDetails.name);
              setCity(result.activeUserWithImagesAndDetails.details.town);
              seteligion(result.activeUserWithImagesAndDetails.details.religion);
              setCast(result.activeUserWithImagesAndDetails.details.cast);
              setLanguage(result.activeUserWithImagesAndDetails.details.spokenLnguage);
              setMobile(result.activeUserWithImagesAndDetails.details.pno);
              setGender(result.activeUserWithImagesAndDetails.details.gender);
              setAge(result.activeUserWithImagesAndDetails.details.age);
              setMarriedStatus(result.activeUserWithImagesAndDetails.marriedStatus);
              console.log(result.activeUserWithImagesAndDetails);
            } else if (response.status === 401) {
              console.log("you are not autherized");
            } else {
              console.log("Some error occured");
            }
          } catch (err) {
            console.log("not Authorized", err);
          }
        };
        getMatchById();

    }, [id]);



    function openCard() {
        setEditPanel(true)
    }

    function closeCard() {
        setEditPanel(false)
    }
    const modalStyle = {
        display: 'block',
        position: 'fixed',
        zIndex: 1000, // Adjust as needed
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.7)', // Semi-transparent black overlay
      };
    
      const modalContentStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      };
    const handleCancel = () => {
        // Add your logic here to determine whether the dialog should be closed or not
        const shouldCloseDialog = true; // Set this to true or false based on your condition

        if (shouldCloseDialog) {
          setOpenDialog(false); // Close the dialog if shouldCloseDialog is true
        }
      };


      
    return (
        <div>   
           {loading &&(  <div className="centered-container" style={{  position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: 10 }}>
                          <span className="loader"></span>
                         </div>
            )}
            {matchData && (
              
                <div class="profile-wrapper" style={{marginTop:'100px'}}>
                  <ToastContainer />
                  <div className="row" style={{marginLeft:'20px'}}>
                    <div className="col-12">
                        <button onClick={backButton} className="btn btn-primary mt-2">
                            <FaArrowLeft />
                        </button>
                        
                    </div>
                </div>


{/* ################################ gihan 02.12.23 #################################### */}

{/* user profile          */}
<label className="block bg-gray-200 p-0 m-5 rounded-lg "><h1 className='prof-head'>User Profile</h1></label>

  <div className="flex flex-col sm:flex-row " >
        {/* Left Side (25% on desktop, 50% on mobile) */}
        

        <div className="w-full  sm:h-full md:h-1/2  bg-gray-200 p-4 left-container" style={{ backgroundColor:'#F8F1D5' }}>
          <div>
           <div className='avatar'>

           <img
              src={
                  (matchData.profilePic.length > 0 && matchData.profilePic[0]) 
                  ? `data:image/png;base64,${matchData.profilePic[0]}` 
                  : (matchData.images.length > 0 && matchData.images[0]) 
                  ? `data:image/png;base64,${matchData.images[0]}` 
                  :matchData.details.gender == 'male'
                  ? profileImageNomale
                  : profileImageNofemale
              }
              alt="User Profile"
                              />
            {/* {matchData.profilePic != null ? (
              <img
                src={`data:image/png;base64,${matchData.profilePic}`}
                alt='openedCard'
                width="30" height="30" className="avatar"
                onClick={(event) => handleClick(event, matchData.profilePic)}
              />
            ) : (
              <img
                src={(gender === 'male') ? profileImageNomale : profileImageNofemale}
                alt='openedCard'
                width="60" height="60" className="avatar"
                onClick={(event) => handleClick(event, matchData.profilePic)}
              />
            )} */}
           </div>

          <h1 className='UserName'>{matchData.fname} {matchData.lname}</h1>
          <div className='UserEmail'>{matchData.email}</div>

          <div className="stacked-button pb-2 sm:pb-4">
            {/* Use 'pb-2' for default padding-bottom and 'sm:pb-4' for small screens */}
            <button className="follow" id="following" style={{ backgroundColor: '#ffb700', borderRadius: '10px', padding:'5px' }} onClick={openCard}>
            <FaEdit /><span className="follow-text">Edit Profile</span>
              <span className="following-text">Following</span>
              <span className="unfollow-text">
                
                Unfollow
              </span>
            </button>
          </div>
        </div>

          
          <div className="stacked-button-2 pb-2 sm:pb-4 ">
          <button className="follow" id="following" style={{ backgroundColor: '#ffb700', borderRadius: '10px' }} onClick={handleLogout}>
          <LuLogOut /><span className="follow-text">Logout</span>
              <span className="following-text">Following</span>
              <span className="unfollow-text">
                Unfollow
              </span>
            </button>
            <ResponsiveDialog
              open={dialogOpen}
              onClose={handleCloseDialog}
              contentText="Are you sure you want to logout? Logging out will end your current session."
              contentTitle="Logout Confirmation"
              actionButtonLabel="Logout"
              onActionButtonClick={logout}
              className="padding50"
            />
        </div>
        </div>


        {/* Right Side (75%) */}   
<div className="grid grid-cols-1 md:grid-cols-2 gap-10 right-container">
    <div className="">
    
      <label className="block bg-gray-200 p-0 rounded-lg label-set"><h1 className='living-place'>Living Country : {matchData.details.livingCountry}</h1></label>
      <label className="block bg-gray-200 p-0 rounded-lg label-set"><h1 className='Country'>Born Country : {matchData.details.bornCountry}</h1></label>
      <label className="block bg-gray-200 p-0 rounded-lg label-set"><h1 className='Language'>Language : {matchData.details.spokenLanguage}</h1></label>
      <label className="block bg-gray-200 p-0 rounded-lg label-set"><h1 className='Age'>Age : {matchData.details.age}</h1></label>
      <label className="block bg-gray-200 p-0 rounded-lg label-set"><h1 className='Education'>Education : {matchData.details.education}</h1></label>
      <label className="block bg-gray-200 p-0 rounded-lg label-set"><h1 className='User-Height'>Height : {matchData.details.heightInFt}</h1></label>
      <label className="block bg-gray-200 p-0 rounded-lg label-set"><h1 className='Work-Dtails'>Work Details : {matchData.details.workDetails}</h1></label>
     
    </div>
    

    <div className="">
  
      <label className="block bg-gray-200 p-0 rounded-lg label-set"><h1 className='Religion'>Religion : {matchData.details.religion}</h1></label>
      <label className="block bg-gray-200 p-0 rounded-lg label-set"><h1 className='Gender'>Gender : {matchData.details.gender}</h1></label>
      <label className="block bg-gray-200 p-0 rounded-lg label-set"><h1 className='Town'>City/Town : {matchData.details.cityTown}</h1></label>
      <label className="block bg-gray-200 p-0 rounded-lg label-set"><h1 className='cast'>Cast : {matchData.details.cast}</h1></label>
      <label className="block bg-gray-200 p-0 rounded-lg label-set"><h1 className='weight'>Weight : {matchData.details.weight}</h1></label>
      <label className="block bg-gray-200 p-0 rounded-lg label-set"><h1 className='weight'>Food Reference : {matchData.details.foodReference}</h1></label>
    </div>

  <div class="profile-body">
   <h1 className='body-head'>Choose Your Image</h1>
    <div className="profile-body-section">  
    {matchData.images.map((url, index) => (
        <img key={index} src={`data:image/png;base64,${url}`} width="110" height="110" alt="openedCard" />
    ))}
    
    <button className='add-btn' onClick={handleButtonClickImagesUploads}>
    <FiPlus className='plus-btn' />
    
    </button>
    </div>
  </div>


   </div>
</div>  

      {/* ############################# gihan #####################################  */}
</div>
            )}

           
            <ConfirmationDialog open={openDialog}  onClose={handleCancel} />

            {(editPanel === true) && (
                <div className='openedCardOverlay' onClick={closeCard}></div>
            )}
            {(editPanel === true) && (

                <div className='openedCardContainer'>
                 
                    <div className='profEdit'>
                   <div className='close-btn-container bg-gradient-to-tr from-amber-900 to-yellow-300'>
                   <button className='close-btn' onClick={closeCard}>
                   <CgClose />
                       </button>
                      
                   </div>
                      
                 
                    
                     <div className="w-861 h-645">
                      
      <h1 className="mb-2 text-center text-2xl font-bold bg-gray-200 rounded-xl p-2">Edit User Details</h1>
      {/* user details form */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-10 full-detailcontainer">
        <div className="each-detailcontainer">
       
      
        <div className="RUserInfoDivCss">
          <div className="mb-4">
                  <label htmlFor="livingCountry" className="block text-gray-700 text-sm font-bold mb-2">
                    Living Country
                  </label>
                  <select
                    id="livingCountry"
                    name="livingCountry"
                    value={userInfo.livingCountry}
                    onChange={(e) => {getCities(e.target.value); handleChange('livingCountry', e.target.value);  addToUserInfo('livingCountry', e.target.value);}}
                    className="w-full px-4 py-3 rounded-lg bg-gray-100 border-gray-300 focus:border-blue-500 focus:bg-white focus:outline-none"
                  >
                    <option value="">{matchData.details.livingCountry ? matchData.details.livingCountry : "Select --"}</option>
                    {countryNames.map((countryName) => (
                      <option key={countryName} value={countryName}>{countryName}</option>
                    
                    ))}
                  </select>
                </div>
          </div>

          <div className="RUserInfoDivCss">
          <div className="mb-4">
                  <label htmlFor="bornCountry" className="block text-gray-700 text-sm font-bold mb-2">
                    Country (Born)
                  </label>
                  <select
                    id="bornCountry"
                    name="bornCountry"
                    value={userInfo.bornCountry}
                    onChange={(e) => {handleChange('bornCountry', e.target.value); addToUserInfo('bornCountry', e.target.value);}}
                    className="w-full px-4 py-3 rounded-lg bg-gray-100 border-gray-300 focus:border-blue-500 focus:bg-white focus:outline-none"
                  >
                    <option value="">{matchData.details.bornCountry ? matchData.details.bornCountry : "Select --"}</option>
                    {countryNames.map((countryName) => (
                      <option key={countryName} value={countryName}>{countryName}</option>
                    
                    ))}
                  </select>
                </div>
          </div>

          <div className="RUserInfoDivCss">
          <div className="mb-4">
                  <label htmlFor="province" className="block text-gray-700 text-sm font-bold mb-2">
                  Province
                  </label>
                  <input
                    id="province"
                    name="province"
                    placeholder="Enter Here"
                    defaultValue={matchData.details.province}
                    // value={userInfo.province}
                    onChange={(e) => {handleChange('province', e.target.value); addToUserInfo('province', e.target.value);}}
                    className="w-full px-4 py-3 rounded-lg bg-gray-100 border-gray-300 focus:border-blue-500 focus:bg-white focus:outline-none"
                  >
                  </input>
                </div>
          </div>

          <div className="RUserInfoDivCss">
          <div className="mb-4">
                  <label htmlFor="cityTown" className="block text-gray-700 text-sm font-bold mb-2">
                     City-Town
                  </label>
                  <input
                    id="cityTown"
                    name="cityTown"
                    placeholder="Enter Here"
                    // value={userInfo.cityTown}
                    defaultValue={matchData.details.cityTown}
                    onChange={(e) => {handleChange('cityTown', e.target.value); addToUserInfo('cityTown', e.target.value);}}
                    className="w-full px-4 py-3 rounded-lg bg-gray-100 border-gray-300 focus:border-blue-500 focus:bg-white focus:outline-none"
                  > 
                  </input>
                </div>
          </div>

           {/* remove the country connection due to client reason 2024.04.24 Gihan */}

          {/* <div className="RUserInfoDivCss">
          <div className="mb-4">
                  <label htmlFor="connectingJaffna" className="block text-gray-700 text-sm font-bold mb-2">
                    How are you connecting with Jaffna?
                  </label>
                  <select
                    id="connectingJaffna"
                    name="connectingJaffna"
                    value={userInfo.bornCountryConnection}
                    onChange={(e) => {handleChange('connectingJaffna', e.target.value); addToUserInfo('connectingJaffna', e.target.value);}}
                    className="w-full px-4 py-3 rounded-lg bg-gray-100 border-gray-300 focus:border-blue-500 focus:bg-white focus:outline-none"
                  >
                    <option value="">{matchData.details.connectingJaffna ? matchData.details.connectingJaffna : "--Select"}</option>
                    
                    <option value="I was born in Jaffna.">I was born in Jaffna.</option>
<option value="My father was born in Jaffna.">My father was born in Jaffna.</option>
<option value="My mother was born in Jaffna.">My mother was born in Jaffna.</option>
<option value="Both my Parents are born in Jaffna.">Both my Parents are born in Jaffna.</option>
<option value="My Grandfather was born in Jaffna.">My Grandfather was born in Jaffna.</option>
<option value="My Grandmother was Born in Jaffna.">My Grandmother was Born in Jaffna.</option>
<option value="Both My Grandparents are born in Jaffna.">Both My Grandparents are born in Jaffna.</option>
<option value="My great Grandparents are born in Jaffna.">My great Grandparents are born in Jaffna.</option>
<option value="My Generation originated in Jaffna.">My Generation originated in Jaffna.</option>

                    
                   
                  </select>
                </div>
          </div> */}
         
          <div className="RUserInfoDivCss">
          <div className="mb-4">
                  <label htmlFor="religion" className="block text-gray-700 text-sm font-bold mb-2">
                    Religion
                  </label>
                  <select
                    id="religion"
                    name="religion"
                    value={userInfo.religion}
                    onChange={(e) => {setCastAccordingToReligion(e.target.value); handleChange('religion', e.target.value); setDenominationAccordingToReligion(e.target.value); addToUserInfo('religion', e.target.value);}}
                    className="w-full px-4 py-3 rounded-lg bg-gray-100 border-gray-300 focus:border-blue-500 focus:bg-white focus:outline-none"
                  >
                    <option value="">{matchData.details.religion ? matchData.details.religion : "Select --"}</option>
                    <option value="hindu">Hindu</option>
                    <option value="christianity">Christianity</option>
                    <option value="muslim">Muslim</option>
                    <option value="atheist">Atheist</option>
                    <option value="none">Not Following a Religion</option>

                  </select>
                </div>
          </div>

          <div className="RUserInfoDivCss">
          <div className="mb-4">
                  <label htmlFor="cast" className="block text-gray-700 text-sm font-bold mb-2">
                    Cast
                  </label>
                  <select
                    id="cast"
                    name="cast"
                    value={userInfo.cast}
                    onChange={(e) => {handleChange('cast', e.target.value); addToUserInfo('cast', e.target.value);}}
                    className="w-full px-4 py-3 rounded-lg bg-gray-100 border-gray-300 focus:border-blue-500 focus:bg-white focus:outline-none"
                  >
                    <option value="">{matchData.details.cast ? matchData.details.cast : "Select --"}</option>
                    {castList.map((cast) => (
                      <option key={cast} value={cast}>{cast}</option>
                    ))}
                  </select>
                </div>
          </div>

          <div className="RUserInfoDivCss">
          <div className="mb-4">
                  <label htmlFor="cast" className="block text-gray-700 text-sm font-bold mb-2">
                    Denomination
                  </label>
                  <select
                    id="denomination"
                    name="denomination"
                    value={userInfo.denomination}
                    onChange={(e) => {handleChange('denomination', e.target.value); addToUserInfo('denomination', e.target.value);}}
                    className="w-full px-4 py-3 rounded-lg bg-gray-100 border-gray-300 focus:border-blue-500 focus:bg-white focus:outline-none"
                  >
                    <option value="">{matchData.details.denomination ? matchData.details.denomination : "Select --"}</option>
                    {DenominationList.map((Denomination) => (
                      <option key={Denomination} value={Denomination}>{Denomination}</option>
                    ))}
                  </select>
                </div>
          </div>
        
          <div className="RUserInfoDivCss">
          <div className="mb-4">
                  <label htmlFor="marriedStatus" className="block text-gray-700 text-sm font-bold mb-2">
                    Married Status
                  </label>
                  <select
                    id="marriedStatus"
                    value={userInfo.marriedStatus}
                    onChange={(e) => {handleChange('marriedStatus', e.target.value); addToUserInfo('marriedStatus', e.target.value);}}
                    className="w-full px-4 py-3 rounded-lg bg-gray-100 border-gray-300 focus:border-blue-500 focus:bg-white focus:outline-none"
                  >
                    <option value="">{matchData.details.marriedStatus ? matchData.details.marriedStatus : "Select Status --"}</option>
                    <option value="single">Single</option>
                    <option value="married">Married</option>
                    <option value="divorce">Divorce</option>
                  </select>
                </div>
          </div>

          <div className="RUserInfoDivCss">
          <div className="mb-4">
                  <label htmlFor="gender" className="block text-gray-700 text-sm font-bold mb-2">
                     Gender
                  </label>
                  <select
                    id="gender"
                    name="gender"
                    value={userInfo.gender}
                    onChange={(e) => {handleChange('gender', e.target.value); addToUserInfo('gender', e.target.value);}}
                    className="w-full px-4 py-3 rounded-lg bg-gray-100 border-gray-300 focus:border-blue-500 focus:bg-white focus:outline-none"
                  >
                    <option value="">{matchData.details.gender ? matchData.details.gender : "Select --"}</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </select>
                </div>
          </div>

          <div className="RUserInfoDivCss">
          <div className="mb-4">
                  <label htmlFor="age" className="block text-gray-700 text-sm font-bold mb-2">
                    Age
                  </label>
                  <input
                    type="number"
                    id="age"
                    name="age"
                    placeholder="Enter Here"
                    // value={userInfo.age}
                    defaultValue={matchData.details.age}
                    onChange={(e) => {handleChange('age', e.target.value); addToUserInfo('age', e.target.value);}}
                    className="w-full px-4 py-3 rounded-lg bg-gray-100 border-gray-300 focus:border-blue-500 focus:bg-white focus:outline-none"
                  />
                </div>
          </div>
  
        </div>


          <div className="each-detailcontainer">

          <div className="RUserInfoDivCss">
          <div className="mb-4">
                  <label htmlFor="education" className="block text-gray-700 text-sm font-bold mb-2">
                    Education
                  </label>
                  <select
                    id="education"
                    name="education"
                    value={userInfo.education}
                    onChange={(e) => {handleChange('education', e.target.value); addToUserInfo('education', e.target.value);}}
                    className="w-full px-4 py-3 rounded-lg bg-gray-100 border-gray-300 focus:border-blue-500 focus:bg-white focus:outline-none"
                  >
                    <option value="">{matchData.details.education ? matchData.details.education : "Select --"}</option>
                    <option value="o/l">O/L</option>
                    <option value="a/l">A/L</option>
                    <option value="Diploma">Diploma</option>
                    <option value="Higher Diploma">Higher Diploma</option>
                    <option value="bachelor">Bachelor</option>
                    <option value="msc">MSc</option>
                    <option value="phd">PhD</option>
                    <option value="other">Other</option>
                  </select>
                </div>
          </div>

          <div className="RUserInfoDivCss">
          <div className="mb-4">
                  <label htmlFor="jobtitle" className="block text-gray-700 text-sm font-bold mb-2">
                  Job Title
                  </label>
                  <input
                    id="jobtitle"
                    name="jobtitle"
                    // value={userInfo.jobtitle}
                    defaultValue={matchData.details.jobtitle}
                    placeholder="Enter Here"
                    onChange={(e) => {handleChange('jobtitle', e.target.value); addToUserInfo('jobtitle', e.target.value);}}
                    className="w-full px-4 py-3 rounded-lg bg-gray-100 border-gray-300 focus:border-blue-500 focus:bg-white focus:outline-none"
                  >
                  </input>
                </div>
          </div>

          <div className="RUserInfoDivCss">
          <div className="mb-4">
                  <label htmlFor="workDetails" className="block text-gray-700 text-sm font-bold mb-2">
                    Work Details
                  </label>
                  <textarea
                    id="workDetails"
                    name="workDetails"
                    placeholder="Enter Here"
                    // value={userInfo.workDetails}
                    defaultValue={matchData.details.workDetails}
                    onChange={(e) => {handleChange('workDetails', e.target.value); addToUserInfo('workDetails', e.target.value);}}
                    className="w-full px-4 py-3 rounded-lg bg-gray-100 border-gray-300 focus:border-blue-500 focus:bg-white focus:outline-none"
                  ></textarea>
                </div>
          </div>


          <div className="RUserInfoDivCss">
          <div className="mb-4">
                  <label htmlFor="pno" className="block text-gray-700 text-sm font-bold mb-2">
                    Phone no
                  </label>
                  <input
                    type="number"
                    id="pno"
                    name="pno"
                    defaultValue={matchData.details.pno}
                    // value={userInfo.mobile}
                    placeholder="07X-XXXX-XXX"
                    onChange={(e) => {handleChange('pno', e.target.value); addToUserInfo('mobile', e.target.value);}}
                    className="w-full px-4 py-3 rounded-lg bg-gray-100 border-gray-300 focus:border-blue-500 focus:bg-white focus:outline-none"
                  />
                </div>
          </div>

          <div className="RUserInfoDivCss">
          <div className="mb-4">
                  <label htmlFor="spokenLanguage" className="block text-gray-700 text-sm font-bold mb-2">
                  Spoken Language
                  </label>
                  <select
                    id="spokenLanguage"
                    name="spokenLanguage"
                    value={userInfo.spokenLanguage}
                    onChange={(e) => {handleChange('spokenLanguage', e.target.value); addToUserInfo('spokenLanguage', e.target.value);}}
                    className="w-full px-4 py-3 rounded-lg bg-gray-100 border-gray-300 focus:border-blue-500 focus:bg-white focus:outline-none"
                  >
                    <option value="">{matchData.details.spokenLanguage ? matchData.details.spokenLanguage : "Select --"}</option>
                    <option value="tamil">Tamil</option>
                    <option value="english">English</option>
                    <option value="sinhala">Sinhala</option>
                  </select>
                </div>
          </div>
        
          
          <div className="RUserInfoDivCss">
          <div className="mb-4">
                  <label htmlFor="heightInFt" className="block text-gray-700 text-sm font-bold mb-2">
                  Height in ft
                  </label>
                    <select
                id="heightInFt"
                name="heightInFt"
                value={userInfo.HeightinFt}
                onChange={(e) => {
                  handleChange('heightInFt', e.target.value);
                  addToUserInfo('heightInFt', e.target.value);
                }}
                className="w-full px-4 py-3 rounded-lg bg-gray-100 border-gray-300 focus:border-blue-500 focus:bg-white focus:outline-none"
              >
                <option value="">select --</option>
                {HeightList.map((title, index) => (
                  <option key={index} value={title}>{title}</option>
                ))}
              </select>
                </div>
          </div>


          
          <div className="RUserInfoDivCss">
          <div className="mb-4">
                  <label htmlFor="weight" className="block text-gray-700 text-sm font-bold mb-2">
                    Weight
                  </label>
                  <input
                    type="text"
                    id="weight"
                    name="weight"
                    placeholder="Enter Here"
                    // value={userInfo.weight}
                    defaultValue={matchData.details.weight}
                    onChange={(e) => {handleChange('weight', e.target.value); addToUserInfo('weight', e.target.value);}}
                    className="w-full px-4 py-3 rounded-lg bg-gray-100 border-gray-300 focus:border-blue-500 focus:bg-white focus:outline-none"
                  />
                </div>
          </div>

          <div className="RUserInfoDivCss">
          <div className="mb-4">
                  <label htmlFor="foodReference" className="block text-gray-700 text-sm font-bold mb-2">
                  Food Preference
                  </label>
                  <select
                    id="foodReference"
                    name="foodReference"
                    value={userInfo.foodReference}
                    onChange={(e) => {handleChange('foodReference', e.target.value); addToUserInfo('foodReference', e.target.value);}}
                    className="w-full px-4 py-3 rounded-lg bg-gray-100 border-gray-300 focus:border-blue-500 focus:bg-white focus:outline-none"
                  >
                    <option value="">{matchData.details.foodReference ? matchData.details.foodReference : "Select --"}</option>
                    <option value="Vegetarian">Vegetarian</option>
                    <option value="Non-Vegetarian">Non-Vegetarian</option>
                    <option value="Vegan">Vegan</option>
                  </select>
                </div>
          </div>

          </div>
          </div>
      <div >
                     <div className='save-btn-container bg-gradient-to-tr  from-amber-900 to-yellow-300 '>
                     <span className='Span-style'><FaSave /></span>
                      <button className='save-btn' onClick={updateFormValues}>             
                      <span className='Save'>{loadingUpdate ? 'Saving...' : 'Save'}</span>

                      
                      </button>
                    </div>
                     </div>
                     </div>
                     
                     
                     
                     
                     
                    </div>
                    
                </div>
            )}


            <Footer />

        </div>
    )
}

export default ProfilePage